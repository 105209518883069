import { SET_ACCOUNT_OPEN } from 'types/actions/User.actions';
import { AppActions } from '../../types';

const INIT_STATE: { isAccountOpen: boolean; } = {
  isAccountOpen: false
};

const User = (state = INIT_STATE, action: AppActions) => {
  switch (action.type) {
    case SET_ACCOUNT_OPEN: {
      console.log('SET_ACCOUNT_OPEN')
      return {
        ...state,
        isAccountOpen: action.isAccountOpen,
      };
    }
    default:
      return state;
  }
};
export default User;
