import moment from 'moment';

const serverFormat = 'MM/DD/YYYY hh:mm a';
const frontFormat = 'YYYY-MM-DDTHH:mm';

const now = () => {
  return moment().format(frontFormat);
};
const nowDate = () => {
  return moment().toDate();
};
const isPast = (date: string) => {
  return moment(date).diff(moment()) < 0;
};
const addMinutes = (date: Date, minutes: number) => {
  return moment(date).add(minutes, 'minutes').format(frontFormat);
};
const addDays = (date: Date, days: number) => {
  return moment(date).add(days, 'days').format(frontFormat);
};
const convertToServerFormatFromString = (date: string) => {
  return moment(date).format(serverFormat);
};
const convertToServerFormat = (date: Date) => {
  return moment(date).format(serverFormat);
};

const convertToFrontFormat = (date: Date) => {};

export const dateHelper = {
  now,
  nowDate,
  isPast,
  addMinutes,
  addDays,
  convertToServerFormatFromString,
  convertToServerFormat,
  convertToFrontFormat,
};
