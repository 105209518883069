import React from 'react';

import {Box} from '@material-ui/core';

import {styles} from './separator-line.styles';

export const SeparatorLine: React.FC = () => {
  const classes = styles();
  return (
    <Box className={classes.separator}></Box>
  );
};
