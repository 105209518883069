export const emptyLocation = (id: number) => {
  return {
    id: id,
    placeName: '',
    lat: 0.0,
    long: 0,

    typeId: 1,
    name: '',
    contactName: '',
    contactPhone: ''
  }
}