import * as yup from 'yup';

export const FormSignupCarrierValidationSchema = (role: string) => {
  const FILE_SIZE = 5 * 1024 * 1024;
  const SUPPORTED_FORMATS = ['application/pdf'];
  const fileValidation = yup
    .mixed()
    .required('A file is required')
    .test(
      'fileSize',
      'File too large',
      (value) => value && value.size <= FILE_SIZE,
    )
    .test(
      'fileFormat',
      'Unsupported Format',
      (value) => value && SUPPORTED_FORMATS.includes(value.type),
    );
  return yup.object({
    mcNumber: yup
      .number()
      .positive('Please enter your MC Number')
      .required('Please enter your MC Number'),
    dotNumber: yup
      .number()
      .positive('Please enter your DOT Number')
      .required('Please enter your DOT Number'),
    firstFile: fileValidation,
    secondFile: fileValidation,
    thirdFile: fileValidation,
    acceptTerms: yup
      .bool()
      .oneOf([true], 'Accept Terms & Conditions is required'),
  });
};