import React from 'react';

import {Permissions} from 'shared/constants/Permissions';

export const loadsConfig = [
  {
    auth: [Permissions.LOADS_SHIPPER],
    routes: [
      {
        path: '/loads/shipper',
        component: React.lazy(() => import('./load-list/load-list.page')),
      },
    ],
  },
  {
    auth: [Permissions.LOADS_CARRIER],
    routes: [
      {
        path: '/loads/carrier',
        component: React.lazy(() => import('./load-list/load-list.page')),
      },
    ],
  },
  {
    auth: [Permissions.LOAD_CREATE],
    routes: [
      {
        path: '/load',
        component: React.lazy(() => import('./load-create/load-create.page')),
      },
    ],
  },
  {
    auth: [Permissions.LOAD_CREATE],
    routes: [
      {
        path: '/loads/upload',
        component: React.lazy(() => import('./load-upload/load-upload.page')),
      },
    ],
  },
  {
    auth: [Permissions.LOAD_CREATE],
    routes: [
      {
        path: '/loads/edit',
        component: React.lazy(() => import('./load-edit/load-edit.page')),
      },
    ],
  },
];
