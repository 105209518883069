import React, {useEffect, useState, useCallback} from 'react';
import {useDispatch} from 'react-redux';

import {
  Table,
  TableContainer,
  TablePagination,
  Paper,
  Box,
  debounce,
} from '@material-ui/core';

import {styles} from './table-view.styles';
import {TableConfig, TableViewProps} from './table-view.types';
import {initialTableData} from './table-view.constants';
import {onTableList} from '../../../redux/actions';

import {
  Order,
  TableViewBody,
  TableViewHead,
  TableViewToolbar,
} from 'modules/molecules';

export const TableView: React.FC<TableViewProps> = (props) => {
  const dispatch = useDispatch<any>();
  const {columns, initialTableConfig, title, url, actions} = props;
  const classes = styles();

  const [tableData, setTableData] = useState<any>(initialTableData);
  const [tableConfig, setTableConfig] = useState<TableConfig>(
    initialTableConfig,
  );

  const [loading, setLoading] = useState(false);

  const reloadTableData = useCallback(() => {
    dispatch(onTableList(url, tableConfig)).then((data: any) => {
      console.log('onTableList - then', data);
      setLoading(true);
      setTableData(data !== undefined ? data : initialTableData);
      setLoading(false);
    });
  }, [dispatch, tableConfig, url]);

  useEffect(() => {
    reloadTableData();
  }, [reloadTableData]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setTableConfig({
      ...tableConfig,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setTableConfig({
      ...tableConfig,
      page: 0,
      pageSize: parseInt(event.target.value, 10),
    });
  };

  const handleSetOrder = (order: Order) => {
    setTableConfig({
      ...tableConfig,
      order: order,
    });
  };

  // const handleSearchChange = (text: string) => {
  //   setTableConfig({
  //     ...tableConfig,
  //     search: text,
  //   });
  // };

  const debouncerSearch = debounce((text: string) => {
    console.log(tableConfig);

    setTableConfig((prev: any) => {
      return {
        ...prev,
        search: text,
      };
    });
  }, 500);

  const handleFilterChange = (name: string, value: any) => {
    const newFilter = {
      ...tableConfig.filters.filter((f) => f.name === name)[0],
    };
    setTableConfig({
      ...tableConfig,
      filters: [
        ...tableConfig.filters.map((f) =>
          f.name === name ? {...newFilter, value: value} : f,
        ),
      ],
    });
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} style={{padding: 25}}>
        <TableViewToolbar
          title={title}
          filters={tableConfig.filters}
          filterChange={handleFilterChange}
          searchChange={debouncerSearch}
        />
        {loading ? (
          <div>Loading</div>
        ) : (
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby='tableTitle'
              size='medium' // 'small'
              aria-label='enhanced table'>
              <TableViewHead
                actions={actions}
                columns={columns}
                order={tableConfig.order as Order}
                setOrder={handleSetOrder}
                rowCount={tableData.data.length}
              />
              <TableViewBody
                data={tableData.data}
                actions={actions}
                columns={columns}
                selectedActionsIds={tableData.actions}
              />
            </Table>
            {tableData.data.length === 0 && (
              <Box style={{textAlign: 'center', padding: 20}}>
                {'No records to display'}
              </Box>
            )}
          </TableContainer>
        )}
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component='div'
          count={tableData.data.length * tableData.totalPages}
          rowsPerPage={tableConfig.pageSize}
          page={tableConfig.page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelDisplayedRows={() => {
            return `${tableConfig.page + 1} of ${tableData.totalPages}`;
          }}
        />
      </Paper>
    </div>
  );
};
