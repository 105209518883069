import React from 'react';
import {Provider} from 'react-redux';
import {ConnectedRouter} from 'connected-react-router';
import AppLayout from '@crema/core/AppLayout';
import AuthRoutes from '@crema/utility/AuthRoutes';
import LocaleProvider from '@crema/utility/LocaleProvider';
import CremaThemeProvider from '@crema/utility/CremaThemeProvider';
import CremaStyleProvider from '@crema/utility/CremaStyleProvider';
import ContextProvider from '@crema/utility/ContextProvider';

import configureStore, {history} from './redux/store';
import CssBaseline from '@material-ui/core/CssBaseline';
import {axiosService, setAuthToken} from './services/axiosService';
import {SIGNOUT_AUTH_SUCCESS} from 'types/actions/Auth.actions';
import {loadUserFromToken} from 'redux/actions';

const store = configureStore();

const App = () => (
  <ContextProvider>
    <Provider store={store}>
      <CremaThemeProvider>
        <CremaStyleProvider>
          <LocaleProvider>
            <ConnectedRouter history={history}>
              <AuthRoutes>
                <CssBaseline />
                <AppLayout />
              </AuthRoutes>
            </ConnectedRouter>
          </LocaleProvider>
        </CremaStyleProvider>
      </CremaThemeProvider>
    </Provider>
  </ContextProvider>
);

const {dispatch} = store;
axiosService.jwtAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const access_token:
        | string
        | null = await axiosService.refreshAccessToken();
      if (access_token) {
        setAuthToken(access_token);
        dispatch(loadUserFromToken(access_token));
      } else {
        setTimeout(() => {
          dispatch({type: SIGNOUT_AUTH_SUCCESS});
          localStorage.removeItem('token');
          localStorage.removeItem('refreshToken');
        }, 500);
      }
      return axiosService.jwtAxios(originalRequest);
    }
    return Promise.reject(error);
  },
);

export default App;
