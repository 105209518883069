import React from 'react';

import Box from '@material-ui/core/Box';

import {styles} from './form-signup-organisation.styles';
import {FormSignupOrganizationProps} from './form-signup-organisation.types';

import {FormTextField} from '../../atoms';

export const FormSignupOrganization: React.FC<FormSignupOrganizationProps> = (
  props,
) => {
  const classes = styles();
  return (
    <>
      {/* Company Name */}
      <Box mb={{xs: 5, xl: 8}}>
        <FormTextField
          label='Company name'
          name='companyName'
          type='text'
          className={classes.myTextFieldRoot}
        />
      </Box>

      {/* Contact person name */}
      <Box mb={{xs: 5, xl: 8}}>
        <FormTextField
          label='Contact person name'
          name='companyContactName'
          type='text'
          className={classes.myTextFieldRoot}
        />
      </Box>

      {/* Company address */}
      <Box mb={{xs: 5, xl: 8}}>
        <FormTextField
          label='Company address'
          name='companyAddress'
          type='text'
          className={classes.myTextFieldRoot}
        />
      </Box>

      {/* Company phone */}
      <Box mb={{xs: 5, xl: 8}}>
        <FormTextField
          label='Company phone'
          name='companyPhone'
          type='text'
          className={classes.myTextFieldRoot}
        />
      </Box>

      {/* Email */}
      <Box mb={{xs: 5, xl: 8}}>
        <FormTextField
          label='Company email'
          name='companyEmail'
          type='text'
          className={classes.myTextFieldRoot}
        />
      </Box>
    </>
  );
};
