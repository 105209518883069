import React from 'react';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import {styles} from './horizontal-stepper.styles';
import {HorizontalStepperProps} from './horizontal-stepper.types';

export const HorizontalStepper: React.FC<HorizontalStepperProps> = (props) => {
  const classes = styles();
  return (
    <div className={classes.root}>
      <Stepper activeStep={props.activeStep} alternativeLabel>
        {props.steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {props.activeStep === props.steps.length ? (
          <div>
            <Typography className={classes.instructions}>
              All steps completed
            </Typography>
            <Button>Reset</Button>
          </div>
        ) : (
          <div>
            <div>
              <Button
                disabled={props.activeStep === 0}
                onClick={props.backHandler}
                className={classes.backButton}>
                Back
              </Button>
              <Button
                variant='contained'
                color='primary'
                onClick={
                  props.activeStep === props.steps.length - 1
                    ? props.submitHandler
                    : props.nextHandler
                }>
                {props.activeStep === props.steps.length - 1
                  ? props.submitButtonText
                  : 'Next'}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
