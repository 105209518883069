import React from 'react';

import {Box, Grid} from '@material-ui/core';

import {LabelVerKeyValueProps} from './label-ver-key-value.types'
import { GridContainer } from '@crema';

export const LabelVerKeyValue: React.FC<LabelVerKeyValueProps> = (props) => {
  const {title, value} = props;
  return (
    <GridContainer>
      <Grid item xs={12}>
        <Box color='text.secondary' style={{marginBottom: 5}}> {title}</Box>
        <Box>{value}</Box>
      </Grid>
    </GridContainer>
  );
};
