import React from 'react';

import { Permissions } from "shared/constants/Permissions";

export const offersConfig = [
  {
    auth: [Permissions.OFFER_SUBMIT, Permissions.PROPOSAL_SUBMIT, Permissions.LOAD_PROPOSAL_VIEW], // TODO: fix this for carrier offer view  
    routes: [
      {
        path: '/offer/load',
        component: React.lazy(() => import('./offer-create/offer-create.page')),
      },
    ],
  },
  {
    auth: [Permissions.LOADS_PROPOSALS],  
    routes: [
      {
        path: '/offers/shipper/load',
        component: React.lazy(() => import('./offer-list/offer-list.page')),
      },
    ],
  },
  {
    auth: [Permissions.PROPOSAL_LIST_CARRIER], 
    routes: [
      {
        path: '/offers/carrier',
        component: React.lazy(() => import('./offer-list/offer-list.page')),
      },
    ],
  },
  {
    auth: [Permissions.PROPOSAL_VIEW_ALL], 
    routes: [
      {
        path: '/offers',
        component: React.lazy(() => import('./offer-list/offer-list.page')),
      },
    ],
  },
  {
    auth: [Permissions.TOUR_TRACK_STATUS],  
    routes: [
      {
        path: '/track/tour',
        component: React.lazy(() => import('./offer-track/offer-track.page')),
      },
    ],
  },
  {
    auth: [Permissions.CHAT_USE], 
    routes: [
      {
        path: '/chat/tour',
        component: React.lazy(() => import('./offer-chat/offer-chat.page')),
      },
    ],
  },
];
