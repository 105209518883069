import Settings from './Setting';
import CommonReducer from './CommonReducer';
import Auth from './Auth';
import User from './User';
import Load from './Load';

const reducers = {
  settings: Settings,
  auth: Auth,
  common: CommonReducer,
  user: User,
  load: Load
};

export default reducers;
