import { makeStyles } from '@material-ui/core';

export const styles = makeStyles((theme) => ({
  modalDefault: {},
  modalScrollable: {
    overflowY: 'scroll',
  },
  modalRoot: {
    position: 'absolute',
    width: 576,
    marginTop: 20,
    paddingBottom: 20,
    height: '100%',
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    outline: 'none'
  },
}));