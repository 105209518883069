import React, {ChangeEvent} from 'react';
import {Field} from 'formik';

import Box from '@material-ui/core/Box';
import {Checkbox} from '@material-ui/core';

import {styles} from './form-signup-carrier.styles';
import {FormSignupCarrierProps} from './form-signup-carrier.types';
import {
  truckTypes,
  driverTypes,
  trailerTypes,
} from '../../../shared/constants/AppConst';

import {FormFileField, FormTextField} from '../../atoms';
import {
  AccordionContainer,
  FormListSelector,
  FormListSelectorType,
} from '../../molecules';

export const FormSignupCarrier: React.FC<FormSignupCarrierProps> = (props) => {
  const classes = styles();
  /*const [panelsExpanded, setPanelsExpanded] = useState<boolean[]>([
    false,
    false,
    false,
    false,
  ]);*/

  /*const changePanelExpandedValue = (id: number) => {
    const panelsOpenedCopy = panelsExpanded.concat([]);
    panelsOpenedCopy[id] = !panelsOpenedCopy[id];
    setPanelsExpanded(panelsOpenedCopy);
  };*/

  const onCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    props.setFieldValue(e.target.name, !props.acceptTerms);
    props.setAcceptTerms(!props.acceptTerms);
  };

  const onSetTrucks = (newTrucks: FormListSelectorType[]) => {
    props.setTrucks(newTrucks);
    props.setFieldValue('trucks', newTrucks);
  };
  const onSetDrivers = (newDrivers: FormListSelectorType[]) => {
    props.setDrivers(newDrivers);
    props.setFieldValue('driverTeams', newDrivers);
  };
  const onSetTrailers = (newTrailers: FormListSelectorType[]) => {
    props.setTrailers(newTrailers);
    props.setFieldValue('trailers', newTrailers);
  };

  return (
    <>
      {/* Company details */}
      <AccordionContainer headerText='Company details'>
        <Box className={classes.accordionContainer} ml='6px' mr='6px'>
          <Box mb={{xs: 5, xl: 8}}>
            <FormTextField
              label='MC Number'
              name='mcNumber'
              type='number'
              className={classes.myTextFieldRoot}
            />
          </Box>
          <Box mb={{xs: 5, xl: 8}}>
            <FormTextField
              label='DOT Number'
              name='dotNumber'
              type='number'
              className={classes.myTextFieldRoot}
            />
          </Box>
        </Box>
      </AccordionContainer>

      {/* File upload */}
      <AccordionContainer
        headerText='File Uploads'
        /*panelId={0}
        panelExpanded={panelsExpanded[0]}
        changePanelExpandedValue={() => changePanelExpandedValue(0)*}*/
      >
        <Box className={classes.accordionContainer} ml='6px' mr='6px' mb={4}>
          <Field
            name='firstFile'
            component={FormFileField}
            label='MC Authority'
            accept='application/pdf'
            currentValue={props.firstFileName}
            setCurrentValue={props.setFirstFileName}
          />
          <Field
            name='secondFile'
            component={FormFileField}
            label='Certifications of Insurance'
            accept='application/pdf'
            currentValue={props.secondFileName}
            setCurrentValue={props.setSecondFileName}
          />
          <Field
            name='thirdFile'
            component={FormFileField}
            label='W9 Form'
            accept='application/pdf'
            currentValue={props.thirdFileName}
            setCurrentValue={props.setThirdFileName}
          />
        </Box>
      </AccordionContainer>

      {/* Trucks */}
      <AccordionContainer headerText='Trucks'>
        <Box className={classes.accordionContainer}>
          <FormListSelector
            itemTypes={truckTypes}
            items={props.trucks}
            setItems={onSetTrucks}
            selectLabel='Truck type'
          />
        </Box>
      </AccordionContainer>

      {/* Driver Teams */}
      <AccordionContainer headerText='Driver Teams'>
        <Box className={classes.accordionContainer}>
          <FormListSelector
            itemTypes={driverTypes}
            items={props.drivers}
            setItems={onSetDrivers}
            selectLabel='Driver team type'
          />
        </Box>
      </AccordionContainer>

      {/* Trailers */}
      <AccordionContainer headerText='Trailers'>
        <Box className={classes.accordionContainer}>
          <FormListSelector
            itemTypes={trailerTypes}
            items={props.trailers}
            setItems={onSetTrailers}
            selectLabel='Trailer type'
          />
        </Box>
      </AccordionContainer>

      {/* Accept Terms and Conditions */}
      <Box
        mt={5}
        mb={{xs: 3, xl: 4}}
        display='flex'
        alignItems='center'
        fontSize={15}>
        <Box display='flex' alignItems='center'>
          <Checkbox
            id='acceptTerms'
            name='acceptTerms'
            checked={props.acceptTerms}
            value={props.acceptTerms}
            color='primary'
            className={
              props.formValidated && !props.acceptTerms
                ? classes.textRed
                : classes.textGrey
            }
            onChange={(e) => onCheckboxChange(e)}
          />
          <Box
            className={
              props.formValidated && !props.acceptTerms
                ? classes.textRed
                : classes.textGrey
            }
            component='span'
            mr={2}
            fontSize={15}>
            I agree to
          </Box>
        </Box>
        <Box
          color='primary.main'
          component='span'
          fontSize={15}
          className={
            props.formValidated && !props.acceptTerms
              ? classes.textRed
              : classes.pointer
          }>
          {'Terms & Conditions'}
        </Box>
      </Box>
    </>
  );
};
