import React from 'react';

import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';

import {styles} from './table-view-head.styles';
import {TableViewHeadProps} from './table-view-head.types';

import {TableColumn} from 'modules/organisms';

export const TableViewHead = (props: TableViewHeadProps) => {
  const classes = styles();
  const {actions, columns, order, setOrder} = props;
  const createSortHandler = (column: TableColumn, dir: 'desc' | 'asc') => (
    event: React.MouseEvent<unknown>,
  ) => {
    if (column.sortable) {
      setOrder({
        column: column.key,
        direction: dir === 'desc' ? 'asc' : 'desc',
      });
      console.log('new order: ', {
        column: column.key,
        direction: dir === 'desc' ? 'asc' : 'desc',
      });
    }
  };

  return (
    <TableHead>
      <TableRow>
        {columns.map((column) => {
          return (
            <TableCell
              key={column.key}
              sortDirection={
                order.column === column.key ? order.direction : false
              }>
              {column.sortable ? (
                <TableSortLabel
                  active={order.column === column.key && column.sortable}
                  direction={
                    order.column === column.key ? order.direction : 'asc'
                  }
                  hideSortIcon={!column.sortable}
                  onClick={createSortHandler(column, order.direction)}>
                  {column.title}
                  {order.column === column.key && column.sortable ? (
                    <span className={classes.visuallyHidden}>
                      {order.direction === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              ) : (
                column.title
              )}
            </TableCell>
          );
        })}
        {actions.length > 0 && <TableCell>Actions</TableCell>}
      </TableRow>
    </TableHead>
  );
};
