import React, {useState} from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  useMediaQuery,
} from '@material-ui/core';
import {useTheme} from '@material-ui/core/styles';

import {ModalDateDialogProps} from './modal-date-dialog.types';
import {dateHelper} from 'shared/helpers';

export const ModalDateDialog: React.FC<ModalDateDialogProps> = (props) => {
  const {
    title,
    message,
    buttons,
    buttonFunctions,
    open,
    textPlaceholder,
    currentDate,
    onDateChange,
  } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [error, setError] = useState('');

  const onChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const newDate = e.currentTarget.value as string;
    if (dateHelper.isPast(newDate)) {
      setError('Please select date that is not in the past.');
    } else {
      setError('');
      onDateChange(newDate);
    }
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        aria-labelledby='responsive-dialog-title'>
        <DialogTitle id='responsive-dialog-title'>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
          <TextField
            style={{marginTop: 10}}
            fullWidth
            id={textPlaceholder}
            label={textPlaceholder}
            type='datetime-local'
            InputLabelProps={{
              shrink: true,
            }}
            value={currentDate}
            variant='outlined'
            error={!!error}
            helperText={error}
            onChange={onChange}
          />
        </DialogContent>
        <DialogActions>
          {buttons.map((button, index) => {
            return (
              <Button
                key={'inputDialog' + index}
                onClick={buttonFunctions[index]}
                color='primary'>
                {button}
              </Button>
            );
          })}
        </DialogActions>
      </Dialog>
    </div>
  );
};
