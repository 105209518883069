import React from 'react';

import {Box} from '@material-ui/core';
import {Fonts} from 'shared/constants/AppEnums';

import {ImageLogoProps} from './image-logo.types';
import logo from './logo.png';

export const ImageLogo: React.FC<ImageLogoProps> = (props) => {
  return (
    <Box mb={{xs: 6, md: 8, xl: 18}} textAlign='center'>
      <Box
        component='h2'
        mb={{xs: 3, xl: 6}}
        color='white'
        fontWeight={Fonts.EXTRA_BOLD}
        fontSize={{xs: 24, xl: 26}}>
          <img src={logo} alt='logo' style={{height: props.height}}/>
      </Box>
    </Box>
  );
};
