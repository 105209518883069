import React from 'react';
import {useFormikContext, useField} from 'formik';

import TextField from '@material-ui/core/TextField';

import {styles} from './form-date-time-picker.styles';
import {FormDateTimePickerProps} from './form-date-time-picker.types';

export const FormDateTimePicker: React.FC<FormDateTimePickerProps> = (
  props,
) => {
  const classes = styles();
  const {setFieldValue} = useFormikContext<string>();
  const [field, meta] = useField(props.name);
  return (
    <>
      <TextField
        id={props.name}
        label={props.label}
        type='datetime-local'
        value={field.value}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
        variant='outlined'
        error={!!meta.error}
        helperText={meta.error}
        onChange={(e) => setFieldValue(props.name, e.target.value)}
      />
    </>
  );
};
