import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import SwipeableViews from 'react-swipeable-views';

import {Box, Modal, Tab, Tabs, Typography} from '@material-ui/core';
import {InfoView} from '@crema';

import {AccountData, UserAccountProps} from './user-account.types';
import {styles} from './user-account.styles';
import {AppState} from 'redux/store';
import {onAccountGet, setAccountOpen} from 'redux/actions';
import {Fonts} from 'shared/constants/AppEnums';

import {CardContainer, SnackbarToast} from 'modules/molecules';
import {
  FormAccountCarrier,
  FormAccountOrganization,
  FormAccountShipper,
  FormAccountUser,
} from 'modules/organisms';

function TabPanel(props: any) {
  const {children, value, index, ...other} = props;
  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

const a11yProps = (index: number) => {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
};

const UserAccount: React.FC<UserAccountProps> = (props) => {
  const classes = styles();
  const dispatch = useDispatch<any>();
  const {isAccountOpen} = useSelector<AppState, AppState['user']>(
    ({user}) => user,
  );
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [error, setError] = useState<string>('');
  const [accountData, setAccountData] = useState<AccountData | null>(null);

  useEffect(() => {
    if (isAccountOpen) {
      dispatch(onAccountGet("")).then((data: any) => {
        console.log('onAccountGet - then', data);
        setAccountData(data);
      });
    } else {
      setAccountData(null);
    }
  }, [dispatch, isAccountOpen]);

  const handleClose = () => {
    dispatch(setAccountOpen(false));
  };
  const handleTabChange = (event: any, newValue: number) => {
    setSelectedTab(newValue);
  };
  const handleTabChangeIndex = (index: number) => {
    setSelectedTab(index);
  };

  return (
    <Modal
      aria-labelledby='user-account'
      aria-describedby='user-account-description'
      open={isAccountOpen}
      className={
        selectedTab === 2 && accountData?.carrier
          ? classes.modalScrollable
          : classes.modalDefault
      }
      onClose={handleClose}>
      <div className={classes.modalRoot}>
        <CardContainer>
          <Box px={{xs: 6, sm: 10, xl: 15}} mb={{xs: 5}}>
            <Box
              component='h2'
              mb={{xs: 3, xl: 6}}
              color='text.primary'
              fontWeight={Fonts.REGULAR}
              fontSize={{xs: 24, xl: 26}}>
              {'Account Settings'}
            </Box>
          </Box>

          <Box mb={{xs: 5}}>
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              indicatorColor='primary'
              textColor='primary'
              variant='fullWidth'
              aria-label='full width tabs example'>
              {accountData?.user && <Tab label='User' {...a11yProps(0)} />}
              {accountData?.organization && (
                <Tab label='Organisation' {...a11yProps(1)} />
              )}
              {accountData?.shipper && (
                <Tab label='Shipper' {...a11yProps(2)} />
              )}
              {accountData?.carrier && (
                <Tab label='Carrier' {...a11yProps(2)} />
              )}
            </Tabs>
          </Box>

          {accountData !== null && (
            <SwipeableViews
              axis={'x'}
              index={selectedTab}
              onChangeIndex={handleTabChangeIndex}>
              {accountData?.user && (
                <TabPanel value={selectedTab} index={0} dir={'ltr'}>
                  <FormAccountUser user={accountData.user} />
                </TabPanel>
              )}
              {accountData?.organization ? (
                <TabPanel value={selectedTab} index={1} dir={'ltr'}>
                  <FormAccountOrganization
                    organization={accountData.organization}
                  />
                </TabPanel>
              ) : (
                <></>
              )}
              {accountData?.shipper || accountData?.carrier ? (
                <TabPanel value={selectedTab} index={2} dir={'ltr'}>
                  {accountData?.shipper && (
                    <FormAccountShipper shipper={accountData.shipper} />
                  )}
                  {accountData?.carrier && (
                    <FormAccountCarrier carrier={accountData.carrier} />
                  )}
                </TabPanel>
              ) : (
                <></>
              )}
            </SwipeableViews>
          )}

          <InfoView />
          {error !== '' && (
            <SnackbarToast
              message={error}
              variant='error'
              onCloseHandler={() => setError('')}
            />
          )}
        </CardContainer>
      </div>
    </Modal>
  );
};

export default UserAccount;
