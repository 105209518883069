import React from 'react';

import {TableRow, TableBody, TableCell} from '@material-ui/core';

import {TableViewBodyProps} from './table-view-body.types';

export const TableViewBody = (props: TableViewBodyProps) => {
  const {data, columns, actions} = props;

  const styleColorForRow = (additionFunc: any, row: any) => {
    return additionFunc ? {backgroundColor: additionFunc(row)} : {};
  };

  const renderBackroundColor = (status: string) => {
    if (status === 'Canceled') return 'rgba(255, 0, 0, 0.2)';
    else if (status === 'Created') return 'rgba(192,192,192,0.2)';
  };

  return (
    <>
      <TableBody>
        {data.map((row, index) => {
          const rowFunctions =
            row['status'] === 'Canceled'
              ? row['actions'].slice(2)
              : row['actions'];

          return (
            <TableRow
              style={{backgroundColor: renderBackroundColor(row['status'])}}
              hover
              //onClick={(event) => handleClick(event, row.name)}
              tabIndex={-1}
              key={index}>
              {columns.map((col, index) => {
                return (
                  <TableCell key={index} align='left'>
                    {col.itemRow
                      ? col.itemRow(row)
                      : col.item
                      ? col.item(row[col.key])
                      : row[col.key]}
                  </TableCell>
                );
              })}
              {rowFunctions && rowFunctions.length > 0 && (
                <TableCell key={row.length} align='left'>
                  {rowFunctions.map((actionId: string) => {
                    const act = actions.filter(
                      (action) => action.id === actionId,
                    )[0];
                    const item = {
                      ...act.item,
                      props: {
                        ...act.item.props,
                        style: styleColorForRow(act.colorAdditionFunc, row),
                        children:
                          act.item.props.children +
                          (act.nameAdditionFunc
                            ? act.nameAdditionFunc(row)
                            : ''),
                        onClick: () => {
                          act.action(row);
                        },
                      },
                    };
                    return <span style={{marginRight: 5}}>{item}</span>;
                  })}
                </TableCell>
              )}
            </TableRow>
          );
        })}
      </TableBody>
    </>
  );
};
