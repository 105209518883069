import {makeStyles} from '@material-ui/core/styles';

export const styles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  addButton: {
    marginLeft: '10px',
  },
  myTextFieldRoot: {
    width: '100%',
  },
  formControl: {
    width: '100%',
  },
}));