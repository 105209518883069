import React from 'react';

import {Box, Grid} from '@material-ui/core';

import {LabelHorKeyValueProps} from './label-hor-key-value.types';
import {GridContainer} from '@crema';

export const LabelHorKeyValue: React.FC<LabelHorKeyValueProps> = (props) => {
  const {title, value, noSpacing} = props;
  return (
    <GridContainer style={{width: '100%'}}>
      {noSpacing ? (
        <>
          <Grid item style={{paddingRight: 0}}>
            <Box color='text.secondary'>{title}</Box>
          </Grid>
          <Grid item style={{paddingLeft: 5}}>
            <Box>{value}</Box>
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={4}>
            <Box color='text.secondary'> {title}</Box>
          </Grid>
          <Grid item xs={8}>
            <Box>{value}</Box>
          </Grid>
        </>
      )}
    </GridContainer>
  );
};
