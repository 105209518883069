import { SET_PROPOSAL_SEEN } from 'types/actions/Load.action';
import { AppActions } from '../../types';

const INIT_STATE: { proposalsSeen: string[]; } = {
  proposalsSeen: []
};

const Load = (state = INIT_STATE, action: AppActions) => {
  switch (action.type) {
    case SET_PROPOSAL_SEEN: {
      console.log('SET_PROPOSAL_SEEN')
      let proposalsSeen = Object.assign({}, state.proposalsSeen)
      proposalsSeen[action.proposalSeen.loadId] = action.proposalSeen.lastSeenDate;
      return {
        ...state,
        proposalsSeen: proposalsSeen,
      };
    }
    default:
      return state;
  }
};
export default Load;
