import React, {ChangeEvent} from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import {GridContainer} from '@crema';

import {styles} from './form-list-selector.styles';
import {FormListSelectorType, FormListSelectorPorps} from './form-list-selector.types';

export const FormListSelector: React.FC<FormListSelectorPorps> = (props) => {
  const classes = styles();

  const handleSelectChange = (
    event: ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => {
    const id: number = +event.target.name!;
    const name: string = '' + event.target.value;

    let newValues = props.items.concat([]);
    const typeId = +props.itemTypes.filter((it: any) => it.name === name)[0].id;
    newValues = newValues.map((item: FormListSelectorType) => {
      return item.id === id ? {...item, typeId: typeId} : item;
    });
    console.log(newValues);
    props.setItems(newValues);
  };

  const addNewItem = () => {
    let id = 0;
    props.items.forEach((it: FormListSelectorType) => {
      if (it.id >= id) {
        id = it.id + 1;
      }
    });
    const newItem = {
      id: id,
      typeId: +props.itemTypes[0].id,
      num: 1,
    };
    props.setItems(props.items.concat([newItem]));
  };

  const onNumberChange = (e: any, id: number) => {
    let newValues = props.items.concat([]);
    newValues = newValues.map((item: FormListSelectorType) => {
      return item.id === id ? {...item, num: +e.target.value} : item;
    });
    console.log(newValues);
    props.setItems(newValues);
  };
  
  const removeItemWithId = (id: number) => {
    const newValues = props.items.filter(
      (item: FormListSelectorType) => item.id !== id,
    );
    props.setItems(newValues);
  };

  return (
    <>
      <Box className={classes.addButton} display='flex' justifyContent='left'>
        <Button variant='contained' color='primary' onClick={addNewItem}>
          Add new item
        </Button>
      </Box>
      <List className={classes.root}>
        {props.items.map((value: FormListSelectorType, index) => {
          const someId = props.selectLabel+index
          return (
            <ListItem key={someId} role={undefined} dense button>
              <GridContainer>
                <Grid item xs={7} md={7}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor={someId}>
                      {props.selectLabel}
                    </InputLabel>
                    <Select
                      value={props.itemTypes[value.typeId - 1].name}
                      onChange={(e) => handleSelectChange(e)}
                      inputProps={{
                        name: value.id.toString(),
                        id: someId,
                      }}
                      name='trucks1'>
                      {props.itemTypes.map((itemType: any) => {
                        return (
                          <MenuItem key={itemType.id - 1} value={itemType.name}>
                            {itemType.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={5} md={5}>
                  <TextField
                    label='Count'
                    name={'listInput' + value.id.toString()}
                    variant='outlined'
                    type='number'
                    className={classes.myTextFieldRoot}
                    defaultValue={value.num}
                    onChange={(e: any) => onNumberChange(e, value.id)}
                  />
                </Grid>
              </GridContainer>

              <ListItemSecondaryAction>
                <IconButton
                  edge='end'
                  aria-label='delete'
                  onClick={() => removeItemWithId(value.id)}>
                  <DeleteIcon fontSize='small' />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
    </>
  );
};
