import React from 'react';

import {Icon, IconButton} from '@material-ui/core';

import {TableIconLinkProps} from './table-icon-link.types';

export const TableIconLink: React.FC<TableIconLinkProps> = (props) => {
  const {icon, url} = props;
  return (
    <>
      <IconButton onClick={() => window.open(url)}>
        <Icon color='action'>{icon}</Icon>
      </IconButton>
    </>
  );
};
