import React from 'react';

import {Button} from '@material-ui/core';

import {TableActionButtonProps} from './table-action-button.types'

export const TableActionButton: React.FC<TableActionButtonProps> = (props) => {
  const {label, color} = props
  return (
    <Button
      key={label}
      style={{marginRight: 5, marginBottom: 5}}
      size='small'
      variant='contained'
      color={color}>
      {label}
    </Button>
  );
};
