import React, {useState} from 'react';
import ReactGoogleMapLoader from 'react-google-maps-loader';
import ReactGooglePlacesSuggest from 'react-google-places-suggest';

import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';

import {TableGooglePlacesProps} from './table-google-places.types';
import {GridContainer} from '@crema';

export const TableGooglePlaces: React.FC<TableGooglePlacesProps> = (props) => {
  const {name, label, onLocationChanged} = props;
  const items = ['50', '100', '150', '200'];
  const [search, setSearch] = useState<string>('');
  const [lat, setLat] = useState<any>(0);
  const [lng, setLng] = useState<any>(0);
  const [distance, setDistance] = useState<string>(items[0]);

  const handleInputChange = (e: any) => {
    setSearch(e.target.value);
    onLocationChanged(name, '');
  };
  const handleSelectSuggest = (
    geocodedPrediction: any,
    originalPrediction: any,
  ) => {
    setSearch(geocodedPrediction.formatted_address);
    console.log(geocodedPrediction);
    const latt = geocodedPrediction.geometry.location.lat();
    setLat(latt);
    const lngg = geocodedPrediction.geometry.location.lng();
    setLng(lngg);
    onLocationChanged(name, {
      lat: '' + latt,
      lng: '' + lngg,
      distance: distance,
    });
  };
  const onChangeHandler = (
    e: React.ChangeEvent<{name?: string | undefined; value: unknown}>,
  ) => {
    console.log(props.name, e.target.value);
    setDistance(e.target.value as string);
    if (lat !== 0 && lng !== 0) {
      onLocationChanged(name, {
        lat: '' + lat,
        lng: '' + lng,
        distance: '' + e.target.value,
      });
    }
  };

  return (
    <ReactGoogleMapLoader
      params={{
        key: process.env.REACT_APP_GOOGLE_MAP_KEY ?? '',
        libraries: 'places,geocode',
      }}
      render={(googleMaps) =>
        googleMaps && (
          <ReactGooglePlacesSuggest
            googleMaps={googleMaps}
            autocompletionRequest={{
              input: search,
              componentRestrictions: {country: 'us'},
            }}
            onSelectSuggest={handleSelectSuggest}
            textNoResults='No results.'
            customRender={(prediction) => (
              <div className='customWrapper' style={{backgroundColor: '#393D4B' }}>
                {prediction ? prediction.description : 'No results.'}
              </div>
            )}>
            <GridContainer style={{marginTop: 0}}>
              <Grid item xs={6} md={6}>
                <TextField
                  name={name}
                  label={label}
                  style={{width: '100%'}}
                  type='text'
                  value={search}
                  placeholder={label}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <FormControl>
                  <InputLabel htmlFor='type-simple'>{'Distance'}</InputLabel>
                  <Select
                    value={distance}
                    onChange={onChangeHandler}
                    name={'distance'}>
                    {items.map((itemType: any) => {
                      return (
                        <MenuItem key={itemType} value={itemType}>
                          {itemType}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </GridContainer>
          </ReactGooglePlacesSuggest>
        )
      }
    />
  );
};
