export enum Permissions {
  SUPER_ADMIN = 101,
  USER_CREATE = 102,
  USER_DELETE = 103,
  USER_UPDATE = 104,
  USER_ACTIVATE = 105,
  USER_BLOCK = 106,
  USERS_SHIPPERS = 107,
  USERS_CARRIERS = 108,
  ORGANIZATIONS_LIST = 109,
  USER_ROLES_CREATEABLE = 110,
  ASSIGN_ORGANIZATION = 111,
  LIST_USERS_IN_ORGANIZATION = 112,
  LIST_OF_ROLES_TO_FILTER_USERS_WITH = 113,
  DISPLAY_USERS_SIDEMENU = 114,
  LOAD_CREATE = 201,
  LOAD_EDIT = 202,
  LOADS_SHIPPER = 203,
  LOADS_CARRIER = 204,
  LOAD_DELETE = 205,
  LOADS_PROPOSALS = 206,
  LOAD_PROPOSAL_VIEW = 207,
  LOAD_GET = 208,
  LOAD_IMPORT = 209,
  OFFER_SUBMIT = 301,
  PROPOSAL_LIST_CARRIER = 401,
  PROPOSAL_SUBMIT = 402,
  PROPOSAL_CANCEL = 403,
  PROPOSAL_VIEW = 404,
  PROPOSAL_CHANGE_STATUS = 405,
  PROPOSAL_VIEW_ALL = 406,
  PROPOSAL_RECEIVE_NEW_NOTIFICATION = 407,
  TOUR_CREATE = 501,
  TOUR_ASSIGN = 502, // Can assign tour to someone.
  TOURS_ALL = 503,
  TOURS_ASSIGNED = 504, // Mobile only
  TOUR_ASSIGNEE = 505, // Can be assigned as a driver.
  TOUR_ADD_EVENT = 506, // For sending location to server. 
  TOUR_RATE = 507,
  TOUR_GET_RATING_CRITERIA = 508,
  TOUR_CHANGE_STATUS = 509, // To change status active, pause...
  TOUR_GET = 510,
  TOUR_ASSIGNEES_LIST = 511,
  TOUR_GET_EVENTS = 512,
  TOUR_TRACK_STATUS = 513,
  CHAT_USE = 602,
  ACCOUNT_GET_USER = 701, 
  ACCOUNT_GET_ORGANIZATION = 702, 
  ACCOUNT_GET_SHIPPER = 703, 
  ACCOUNT_GET_CARRIER = 704, 
  ACCOUNT_CHANGE_ORGANIZATION = 705, 
  ACCOUNT_CHANGE_CARRIER = 706, 
  ACCOUNT_CHANGE_SHIPPER = 707, 
  ACCOUNT_EDIT_USER_PROFILE = 708, 
}