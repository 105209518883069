import React from 'react';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {styles} from './accordion-container.styles';
import {AccordionContainerProps} from './accordion-container.types';

export const AccordionContainer: React.FC<AccordionContainerProps> = (
  props,
) => {
  const classes = styles();
  return (
    <Accordion
      defaultExpanded={props.defaultExpanded ?? true}
    /*expanded={props.panelExpanded}
      onChange={props.changePanelExpandedValue}*/
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1bh-content'
        id='panel1bh-header'>
        <Typography className={classes.heading}>{props.headerText}</Typography>
      </AccordionSummary>
      <AccordionDetails>{props.children}</AccordionDetails>
    </Accordion>
  );
};
