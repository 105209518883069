import React from 'react';

export const samplePagesConfig = [
  {
    auth: [1,2,3],
    routes: [
      {
        path: '/sample/page-1',
        component: React.lazy(() => import('./Pages/PageOne')),
      },
    ],
  },
  {
    auth: [10],
    routes: [
      {
        path: '/sample/page-2',
        component: React.lazy(() => import('./Pages/PageTwo')),
      },
    ],
  },
  /*{
    auth: ['user'],
    routes: [
      {
        path: '/carrier',
        component: React.lazy(() => import('./Pages/PageTwo')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: '/shipper',
        component: React.lazy(() => import('./Pages/PageTwo')),
      },
    ],
  },*/
];
