import React, {useMemo} from 'react';
import {useDropzone} from 'react-dropzone';

import {DropzoneStyledProps} from './dropzone-styled.types';

const baseStyle = {
  flex: 1,
  display: 'flex',
  //flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#393D4B',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};
const activeStyle = {
  borderColor: '#2196f3',
};
const acceptStyle = {
  borderColor: '#00e676',
};
const rejectStyle = {
  borderColor: '#ff1744',
};

export const DropzoneStyled: React.FC<DropzoneStyledProps> = (props) => {
  const {accept, file, setFile} = props

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: accept,
    onDrop: (acceptedFiles) => {
      setFile(acceptedFiles[0]);
    },
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragAccept, isDragReject],
  );
  return (
    <div className='container'>
      <div {...getRootProps({style})}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop the file here, or click to select the file</p>
      </div>
      {file && (
        <aside style={{marginTop: 15}}>
          <h6>File name: </h6>
          <ul>{file?.name}</ul>
        </aside>
      )}
    </div>
  );
};
