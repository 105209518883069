import React from 'react';
import {Form, Formik} from 'formik';
import {useDispatch} from 'react-redux';

import {Button, Grid, Box} from '@material-ui/core';
import {GridContainer} from '@crema';

import {styles} from './form-account-user.styles';
import {FormAccountUserProps} from './form-account-user.types';
import {FormAccountUserValidationSchema} from './form-account-user.validation';
import {onAccountUserChange, setAccountOpen} from 'redux/actions';

import {FormTextField} from '../../atoms';

export const FormAccountUser: React.FC<FormAccountUserProps> = (props) => {
  const {user} = props
  const classes = styles();
  const dispatch = useDispatch<any>();
  const initialValues = {
    name: user.name,
    email: user.email,
    username: user.username,
    phone: user.phone,
    oldPassword: '',
    newPassword: '',
  };
  const handleClose = () => {
    dispatch(setAccountOpen(false));
  };

  return (
    <Box flex={1} display='flex' flexDirection='column' justifyContent='center'>
      {/* Form */}
      <Formik
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={FormAccountUserValidationSchema}
        onSubmit={(data, {setErrors, setSubmitting}) => {
          if (data.oldPassword !== data.newPassword) {
            setErrors({
              newPassword:
                'The two passwords you entered did not match!',
            });
          } else {
            setSubmitting(true);
            console.log('form data', data);
            dispatch(onAccountUserChange(data)).then((resData: any) => {
              console.log('onAccountUserChange - then - data - ', resData);
              handleClose()
            });
          }
          setSubmitting(false);
        }}>
        {({isSubmitting, setFieldValue}) => (
          <Form noValidate autoComplete='off'>
            {/* Name */}
            <Box mb={{xs: 5, xl: 8}}>
              <FormTextField
                label='Name'
                name='name'
                type='text'
                className={classes.myTextFieldRoot}
              />
            </Box>

            {/* Email */}
            <Box mb={{xs: 5, xl: 8}}>
              <FormTextField
                label='Email'
                name='email'
                type='text'
                className={classes.myTextFieldRoot}
              />
            </Box>

            {/* Username */}
            <Box mb={{xs: 5, xl: 8}}>
              <FormTextField
                label='Username'
                name='username'
                type='text'
                className={classes.myTextFieldRoot}
              />
            </Box>

            {/* Password */}
            <GridContainer style={{width: 'calc(100% + 28px)'}}>
              <Grid item xs={12} md={6}>
                <Box mb={{xs: 0, xl: 4}}>
                  <FormTextField
                    label='Old Password'
                    name='oldPassword'
                    type='password'
                    className={classes.myTextFieldRoot}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} md={6} style={{paddingRight: 12}}>
                <Box mb={{xs: 5, xl: 8}}>
                  <FormTextField
                    label='New Password'
                    name='newPassword'
                    type='password'
                    className={classes.myTextFieldRoot}
                  />
                </Box>
              </Grid>
            </GridContainer>

            {/* Phone */}
            <Box mb={{xs: 5, xl: 8}}>
              <FormTextField
                label='Phone'
                name='phone'
                type='text'
                className={classes.myTextFieldRoot}
              />
            </Box>

            <Box mb={4}>
              <Button
                variant='contained'
                color='default'
                className={classes.btnRoot}
                style={{marginRight: '20px'}}
                onClick={handleClose}>
                Cancel
              </Button>
              <Button
                variant='contained'
                color='primary'
                disabled={isSubmitting}
                className={classes.btnRoot}
                type='submit'>
                Save Changes
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
