import React, {ChangeEvent} from 'react';

import {
  Box,
  Grid,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {GridContainer} from '@crema';

import {styles} from './table-view-toolbar.styles';
import {TableViewToolbarProps} from './table-view-toolbar.types';

import {TableSelectPicker} from 'modules/atoms';
import { TableFilterTypes } from 'shared/constants/AppEnums';
import { TableGooglePlaces } from 'modules/atoms/table-google-places';

export const TableViewToolbar = (props: TableViewToolbarProps) => {
  const {title, searchChange, filters, filterChange} = props;
  const classes = styles();

  const onTextChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    searchChange(event.currentTarget.value);
  };

  const onSetSelected = (name: string, value: any, allOptions?: any, sendName?: boolean) => {
    if (sendName && allOptions && value.toString() !== '0') {
      const selectedName = allOptions.filter((op: { id: any; name: any;}) => { return op.id === value})[0].name
      filterChange(name, selectedName);
    } else {
      filterChange(name, value);
    }
  };
  const getIdFromName = (name: string, options?: { id: number; name: string; }[]) => {
    if (options && name.toString() !== '0') {
      console.log('test', options, name)
      return options.filter((op: { id: any; name: any;}) => { return op.name === name})[0].id
    }
    console.log('test2', options, name)
    return 0
  }

  return (
    <>
      <Toolbar className={classes.root}>
        <Typography
          className={classes.title}
          variant='h6'
          id='tableTitle'
          component='div'>
          {title}
        </Typography>
        <Tooltip title='Search'>
          <TextField
            style={{maxWidth: 250}}
            margin='dense'
            id='user-name'
            placeholder='Search'
            type='search'
            variant='outlined'
            onChange={onTextChange}
          />
        </Tooltip>
      </Toolbar>
      <GridContainer className={classes.root}>
        {filters.length > 0 ? 
          filters.map((filter) => {
            return (
              <Grid key={filter.name} item xs={12} md={4}>
                <Box key={filter.name} mb={4}>
                  {filter.type === TableFilterTypes.SELECT && 
                    <TableSelectPicker
                      name={filter.name}
                      items={filter.options ?? [{id: 0, name: 'please add options'}]}
                      label={filter.title}
                      selected={filter.sendName ? getIdFromName(filter.value, filter.options) : +filter.value}
                      setSelected={(name: string, value: any) => onSetSelected(name, value, filter.options, filter.sendName)}
                    />
                  }
                  {filter.type === TableFilterTypes.GOOGLE_PLACES && 
                    <TableGooglePlaces
                      name={filter.name}
                      label={filter.title}
                      onLocationChanged={onSetSelected}
                    />
                  }
                </Box>
              </Grid>
            );
          })
          : 
          <Box style={{height: 35}}></Box>
        }
        
      </GridContainer>
    </>
  );
};
