import React from 'react';
import {Form, Formik} from 'formik';
import {useDispatch} from 'react-redux';

import Box from '@material-ui/core/Box';
import {Button} from '@material-ui/core';

import {styles} from './form-account-organisation.styles';
import {FormAccountOrganizationProps} from './form-account-organisation.types';
import {FormAccountOrganizationValidationSchema} from './form-account-organisation.validation';
import {onAccountOrganisationChange, setAccountOpen} from 'redux/actions';

import {FormTextField} from '../../atoms';

export const FormAccountOrganization: React.FC<FormAccountOrganizationProps> = (
  props,
) => {
  const {organization} = props;
  const classes = styles();
  const dispatch = useDispatch<any>();
  const initialValues = {
    companyName: organization.name,
    companyContactName: organization.contactPersonName,
    companyAddress: organization.address,
    companyPhone: organization.phone,
    companyEmail: organization.email,
  };
  const handleClose = () => {
    dispatch(setAccountOpen(false));
  };

  return (
    <Box flex={1} display='flex' flexDirection='column' justifyContent='center'>
      {/* Form */}
      <Formik
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={FormAccountOrganizationValidationSchema}
        onSubmit={(data, {setErrors, setSubmitting}) => {
          setSubmitting(true);
          console.log('form data', data);
          dispatch(onAccountOrganisationChange(data)).then((resData: any) => {
            console.log(
              'onAccountOrganisationChange - then - data - ',
              resData,
            );
            handleClose();
          });
          setSubmitting(false);
        }}>
        {({isSubmitting, setFieldValue}) => (
          <Form noValidate autoComplete='off'>
            {/* Company Name */}
            <Box mb={{xs: 5, xl: 8}}>
              <FormTextField
                label='Company name'
                name='companyName'
                type='text'
                className={classes.myTextFieldRoot}
              />
            </Box>

            {/* Contact person name */}
            <Box mb={{xs: 5, xl: 8}}>
              <FormTextField
                label='Contact person name'
                name='companyContactName'
                type='text'
                className={classes.myTextFieldRoot}
              />
            </Box>

            {/* Company address */}
            <Box mb={{xs: 5, xl: 8}}>
              <FormTextField
                label='Company address'
                name='companyAddress'
                type='text'
                className={classes.myTextFieldRoot}
              />
            </Box>

            {/* Company phone */}
            <Box mb={{xs: 5, xl: 8}}>
              <FormTextField
                label='Company phone'
                name='companyPhone'
                type='text'
                className={classes.myTextFieldRoot}
              />
            </Box>

            {/* Email */}
            <Box mb={{xs: 5, xl: 8}}>
              <FormTextField
                label='Company email'
                name='companyEmail'
                type='text'
                className={classes.myTextFieldRoot}
              />
            </Box>
            <Box mb={4}>
              <Button
                variant='contained'
                color='default'
                className={classes.btnRoot}
                style={{marginRight: '20px'}}
                onClick={handleClose}>
                Cancel
              </Button>
              <Button
                variant='contained'
                color='primary'
                disabled={isSubmitting}
                className={classes.btnRoot}
                type='submit'>
                Save Changes
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
