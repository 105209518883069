import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';

type Variant = 'error' | 'info' | 'success' | 'warning';

export interface AppSnackbarProps {
  className?: string;
  message: string;
  variant: Variant;
  onCloseHandler: () => void;

  [x: string]: any;
}

export const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};