import React, { ChangeEvent } from 'react';
import {useFormikContext, useField} from 'formik';

import { Box, Checkbox } from '@material-ui/core';

import {FormCheckboxProps} from './form-checkbox.types';

export const FormCheckbox: React.FC<FormCheckboxProps> = (
  props,
) => {
  const {setFieldValue} = useFormikContext<string>();
  const [field/*, meta*/] = useField(props);
  
  const onPickupCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFieldValue(props.name, e.target.checked)
  };
  return (
    <Box display='flex' alignItems='center'>
      <Checkbox
        id={props.name}
        name={props.name}
        checked={field.checked}
        value={field.value}
        color='primary'
        onChange={onPickupCheckboxChange}
      />
      <Box component='span' mr={2} fontSize={15}>
        {props.label}
      </Box>
    </Box>
  );
};
