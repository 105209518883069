import React from 'react';
import {useField} from 'formik';
import {useHistory} from 'react-router-dom';

import {Box, Button, Grid} from '@material-ui/core';
import {GridContainer} from '@crema';
import AppCard from '@crema/core/AppCard';

import {FormLoadCreateGeneralProps} from './form-load-create-general.types';
import {loadEquipment} from 'shared/constants/AppConst';

import {
  FormDateTimePicker,
  FormCheckbox,
  SeparatorLine,
  FormSubtitle,
  FormMultipleSelectPicker,
} from '../../atoms';
import {Fonts} from 'shared/constants/AppEnums';

export const FormLoadCreateGeneral: React.FC<FormLoadCreateGeneralProps> = (
  props,
) => {
  const history = useHistory();

  const [fieldCheckboxPickup] = useField('addPickupDatetimeRange');
  const [fieldCheckboxDropoff] = useField('addDropoffDatetimeRange');

  const handleBatchUploadClicked = () => {
    history.push('/loads/upload');
  };

  const loadId = history.location.pathname.split('/')[3] || '';

  return (
    <>
      <Box
        component='h1'
        color='text.primary'
        fontWeight={Fonts.BOLD}
        fontSize={16}
        style={{
          marginBottom: '20px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
        mb={1}>
        {props.isUpdating && `Updating Load ${loadId}`} General info
        <Button
          variant='contained'
          color='default'
          onClick={handleBatchUploadClicked}>
          Batch upload
        </Button>
      </Box>

      <AppCard style={{padding: 20}} mb={5}>
        <Box flex={1} flexDirection='column'>
          <FormSubtitle label='Pickup and dropoff dates and times' />

          <Box style={{width: '100%'}}>
            <GridContainer>
              {/* Pickup dates */}
              <Grid item xs={12} md={6}>
                <Box mb={{xs: 0, xl: 4}}>
                  <FormDateTimePicker
                    name='pickupDatetimeStart'
                    label='Pickup date and time'
                  />
                  <FormCheckbox
                    name='addPickupDatetimeRange'
                    label='Add latest pickup time'
                  />
                  {fieldCheckboxPickup.value && (
                    <Box mt={2} style={{width: '100%'}}>
                      <FormDateTimePicker
                        name='pickupDatetimeEnd'
                        label='Latest pickup date and time'
                      />
                    </Box>
                  )}
                </Box>
              </Grid>

              {/* Dropoff dates */}
              <Grid item xs={12} md={6}>
                <Box mb={{xs: 3, xl: 4}}>
                  <FormDateTimePicker
                    name='dropoffDatetimeStart'
                    label='Dropoff date and time'
                  />
                  <FormCheckbox
                    name='addDropoffDatetimeRange'
                    label='Add latest dropoff time'
                  />
                  {fieldCheckboxDropoff.value && (
                    <Box mt={2} style={{width: '100%'}}>
                      <FormDateTimePicker
                        name='dropoffDatetimeEnd'
                        label='Latest dropoff date and time'
                      />
                    </Box>
                  )}
                </Box>
              </Grid>
            </GridContainer>
          </Box>

          {/* Separator */}
          <SeparatorLine />

          <Box style={{width: '100%'}} mt={6}>
            <GridContainer>
              <Grid item xs={12} md={6}>
                <FormDateTimePicker
                  name='offerExpirationDatetime'
                  label='Offer expiration date and time'
                />
              </Grid>
            </GridContainer>
          </Box>

          <Box style={{width: '100%'}} mt={6}>
            <Grid item xs={12} md={6}>
              <Box mb={{xs: 4, xl: 6}}>
                <FormMultipleSelectPicker
                  name='loadEquipmentSelect'
                  items={loadEquipment}
                  label='Select Load Equipment'
                />
              </Box>
            </Grid>
          </Box>
        </Box>
      </AppCard>
    </>
  );
};
