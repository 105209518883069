import React from 'react';

import Card from '@material-ui/core/Card';
import {Box} from '@material-ui/core';

import {styles} from './card-container.styles';
import {CardContainerProps} from './card-container.types';

export const CardContainer: React.FC<CardContainerProps> = (props) => {
  const classes = styles();
  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'>
      <Card className={classes.card}>{props.children}</Card>
    </Box>
  );
};
