import {AuthUser} from '../../types/models/AuthUser';

export const authRole = {
  admin: ['admin'],
  user: ['user', 'admin'],
};

export const defaultUser: AuthUser = {
  name: 'Srecko Janicijevic',
  username: 'srecko89',
  email: 'srecko.janicijevic@gmail.com',
  organization: 'Srecko INC',
  permissions: [1, 2, 3],
  ratings: undefined,
  roles: [],
};
export const initialUrl = '/loads/shipper'; // this url will open after login

export const truckTypes = [
  {id: 1, name: 'Cargo Van'},
  {id: 2, name: 'Small Straight'},
  {id: 3, name: 'Large Straight'},
  {id: 4, name: 'Tractor'},
  {id: 5, name: 'Flatbed'},
];
export const driverTypes = [
  {id: 1, name: 'Single Driver'},
  {id: 2, name: 'Two Drivers'},
];
export const trailerTypes = [
  {
    id: 1,
    name: "20' Container Chassis",
  },
  {
    id: 2,
    name: '250 Ton Low Boy',
  },
  {
    id: 3,
    name: '35 Ton Mini Deck',
  },
  {
    id: 4,
    name: "40' Container Chassis",
  },
  {
    id: 5,
    name: '48 Foot Dry Van',
  },
  {
    id: 6,
    name: '48 ft Murray TriAxle',
  },
  {
    id: 7,
    name: '48 ft Murray Wheeler',
  },
  {
    id: 8,
    name: '48 ft Reefer',
  },
  {
    id: 9,
    name: '51 ft SELF-LD Tri-Low Step CA Legal',
  },
  {
    id: 10,
    name: '51 ft Tri low step CA Legal',
  },
  {
    id: 11,
    name: '53 ft Foot Dry Van',
  },
  {
    id: 12,
    name: '53 ft Flat Floor/Freight',
  },
  {
    id: 13,
    name: '53 ft Household Goods',
  },
  {
    id: 14,
    name: '53 ft Logistics Trailer Equipped',
  },
  {
    id: 15,
    name: '53 ft Reefer',
  },
  {
    id: 16,
    name: '53 ft Roller Bed Trailer',
  },
  {
    id: 17,
    name: '53 ft Vented Plate Trailer',
  },
  {
    id: 18,
    name: '53 ft Reinforced Trailer',
  },
  {
    id: 49,
    name: '53 ft Plated Trailer',
  },
  {
    id: 19,
    name: '60 Ton 10 Axle',
  },
  {
    id: 20,
    name: '60 Ton 32 Wheeler',
  },
  {
    id: 21,
    name: '75 Ton Multi Axle Low Boy',
  },
  {
    id: 22,
    name: 'CA 9 Axle 60T 16W Jeep',
  },
  {
    id: 23,
    name: 'CA 9 Axle 65T 16W Jeep',
  },
  {
    id: 24,
    name: 'Conestoga',
  },
  {
    id: 25,
    name: 'Curtainside Robotic Van',
  },
  {
    id: 26,
    name: 'Double Drop Deck',
  },
  {
    id: 27,
    name: 'Dry Tanker',
  },
  {
    id: 28,
    name: 'Dump Trailer',
  },
  {
    id: 29,
    name: 'Expandable low bed',
  },
  {
    id: 30,
    name: 'Expandible Single Drop Deck',
  },
  {
    id: 50,
    name: 'Expandible Double Drop Deck',
  },
  {
    id: 51,
    name: 'Expandible Flat Bed',
  },
  {
    id: 31,
    name: 'Ext Low 9 Axle 60T 8W Jeep',
  },
  {
    id: 32,
    name: 'Flat Bed',
  },
  {
    id: 33,
    name: 'Flat Bed Curtaion',
  },
  {
    id: 34,
    name: 'Gooseneck Low Boy',
  },
  {
    id: 35,
    name: 'Liquid Tanker',
  },
  {
    id: 36,
    name: 'Pup Trailer',
  },
  {
    id: 37,
    name: 'Sided Flat Bed',
  },
  {
    id: 38,
    name: 'Single Axle Booster Pin On',
  },
  {
    id: 39,
    name: 'Single Drop Deck',
  },
  {
    id: 40,
    name: 'Single Jeep',
  },
  {
    id: 41,
    name: 'Specialized',
  },
  {
    id: 42,
    name: 'Stake Truck Flat Bed',
  },
  {
    id: 43,
    name: 'Step Deck',
  },
  {
    id: 44,
    name: 'Step Deck Curtain',
  },
  {
    id: 45,
    name: 'Tandem Booster Hyd Nitro 2nd Axle',
  },
  {
    id: 46,
    name: 'Tandem Booster Hyd Relief',
  },
  {
    id: 47,
    name: 'Tandem Jeep',
  },
  {
    id: 48,
    name: 'Tanker',
  },
];
export const loadTypes = [
  {id: 1, name: 'FTL'},
  {id: 2, name: 'LTL'},
];
export const locationTypes = [
  {id: 1, name: 'Pickup Location'},
  {id: 2, name: 'Extra Pickup Stop'},
  {id: 3, name: 'Extra Dropoff Stop'},
  {id: 4, name: 'Dropoff Location'},
  // { id: 5, name: 'Checkpoint' },
];
export const carrierSortingTypes = [
  {id: 1, name: 'Lowest bid'},
  {id: 2, name: 'Closest Truck'},
  {id: 3, name: 'Carrier Rating'},
];
export const loadAccesories = [
  {id: 1, name: 'Pallet Jack'},
  {id: 2, name: 'Driver Assist'},
  {id: 3, name: 'In Bond'},
  {id: 4, name: 'Liftgate'},
  {id: 5, name: 'Protective Gear'},
  {id: 6, name: 'Twic Required'},
  {id: 7, name: 'Food Grade Trailer'},
  {id: 8, name: 'Tarping'},
  {id: 9, name: 'PipeStakes'},
];
export const loadEquipment = [
  {id: 1, name: 'FlatBed'},
  {id: 2, name: 'Dry Van'},
  {id: 3, name: 'Reefer'},
];
export const generalStatuses = [
  {id: 1, name: 'Pending'},
  {id: 2, name: 'Active'},
  {id: 3, name: 'Blocked'},
  {id: 4, name: 'Created'},
  {id: 5, name: 'Accepted'},
  {id: 6, name: 'Signed'},
  {id: 7, name: 'Canceled'},
  {id: 8, name: 'Paused'},
  {id: 9, name: 'Completed'},
  {id: 10, name: 'Submitted Proposal'},
  {id: 11, name: 'Pending Acceptance'},
  {id: 12, name: 'Declined'},
  {id: 13, name: 'Pending Confirmation'},
  {id: 14, name: 'Confirmed'},
];
export const usersTableStatuses = [
  {id: 2, name: 'Active'},
  {id: 3, name: 'Blocked'},
];
export const userRoles = [
  {id: 1, name: 'Superadmin'},
  {id: 2, name: 'Shipper'},
  {id: 3, name: 'Carrier'},
];
export const loadStatuses = [
  {id: 1, name: 'Created'},
  {id: 2, name: 'Ready for pickup'},
  {id: 3, name: 'Dispatched'},
  {id: 4, name: 'Delivered'},
  {id: 5, name: 'Canceled'},
  {id: 6, name: 'Confirmed'},
  {id: 7, name: 'Bid Accepted'},
  {id: 8, name: 'Carrier Confirmed'},
  {id: 9, name: 'In Transit'},
  {id: 10, name: 'Pending Delivery Confirmation'},
  {id: 11, name: 'Delivered'},
];

export const amountTypes = [
  {id: 1, name: '< $100,000.00'},
  {id: 2, name: '> $100,000.00'},
];
