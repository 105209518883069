import React from 'react';
import {useField} from 'formik';

import {TextField} from '@material-ui/core';

import {styles} from './form-text-area.styles';
import {FormTextFieldProps} from './form-text-area.types';

export const FormTextArea: React.FC<FormTextFieldProps> = (props) => {
  const classes = styles();
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : '';
  return (
    <TextField
      {...field}
      type={props.type}
      label={props.label}
      multiline
      rowsMax={props.rowsMax}
      rows={props.rows}
      className={[classes.textField, props.className].join(' ')}
      margin='normal'
      helperText={errorText}
      error={!!errorText}
      variant='outlined'
      disabled={props.disabled}
    />
  );
};