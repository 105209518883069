import { Dispatch } from 'redux';

import { axiosService } from '../../services';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import { AppActions } from '../../types';

export const onTableList = (url: string, data: any) => { 
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const res = await axiosService.tableList(url, data)
      dispatch(fetchSuccess());
      return Promise.resolve(res);
    } catch (err: any) {
      dispatch(fetchError(err));
    }
  };
}