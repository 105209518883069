import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    paddingBottom: theme.spacing(5),
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  }
}));