import React from 'react';
import {Redirect} from 'react-router-dom';

import {createRoutes} from '../@crema/utility/Utils';

import {initialUrl} from '../shared/constants/AppConst';
import {samplePagesConfig} from './pages/sample';
import {errorPagesConfigs} from './pages/errorPages';
import {authRouteConfig} from './pages/auth';
import {loadsConfig} from './pages/loads';
import {toursConfig} from './pages/tours';
import {usersConfig} from './pages/users';
import {offersConfig} from './pages/offers';
import {otherConfig} from './pages/other';

const routeConfigs = [
  ...samplePagesConfig,
  ...errorPagesConfigs,
  ...authRouteConfig,
  ...loadsConfig,
  ...toursConfig,
  ...usersConfig,
  ...offersConfig,
  ...otherConfig,
];

const routes = [
  ...createRoutes(routeConfigs),
  {
    path: '/',
    exact: true,
    component: () => <Redirect to={initialUrl} />,
  },
  {
    component: () => <Redirect to='/error-pages/error-404' />,
  },
];

export default routes;
