import React from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import {GridContainer} from '../../../@crema';

import {styles} from './form-signup-user.styles';
import {FormSignupUserProps} from './form-signup-user.types';

import {FormTextField} from '../../atoms';

export const FormSignupUser: React.FC<FormSignupUserProps> = (props) => {
  const classes = styles();
  return (
    <>
      {/* Name */}
      <Box mb={{xs: 5, xl: 8}}>
        <FormTextField
          label='Name'
          name='name'
          type='text'
          className={classes.myTextFieldRoot}
        />
      </Box>

      {/* Email */}
      <Box mb={{xs: 5, xl: 8}}>
        <FormTextField
          label='Email'
          name='email'
          type='text'
          className={classes.myTextFieldRoot}
        />
      </Box>

      {/* Username */}
      <Box mb={{xs: 5, xl: 8}}>
        <FormTextField
          label='Username'
          name='username'
          type='text'
          className={classes.myTextFieldRoot}
        />
      </Box>

      {/* Password */}
      <GridContainer>
        <Grid item xs={12} md={6}>
          <Box mb={{xs: 0, xl: 4}}>
            <FormTextField
              label='Password'
              name='password'
              type='password'
              className={classes.myTextFieldRoot}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box mb={{xs: 3, xl: 4}}>
            <FormTextField
              label='Retype Password'
              name='confirmPassword'
              type='password'
              className={classes.myTextFieldRoot}
            />
          </Box>
        </Grid>
      </GridContainer>

      {/* Phone */}
      <Box mb={{xs: 5, xl: 8}}>
        <FormTextField
          label='Phone'
          name='phone'
          type='text'
          className={classes.myTextFieldRoot}
        />
      </Box>
    </>
  );
};
