import * as yup from 'yup';
import { PhoneNumberValidator } from '../../../shared/validators';

export const FormSignupOrganizationValidationSchema = (role: string) => {
  return yup.object({
    companyName: yup.string().required('Please enter company name!'),
    companyContactName: yup
      .string()
      .required('Please enter password contact person name!'),
    companyAddress: yup.string().required('Please enter company address!'),
    companyPhone: new yup.string().required('Please enter phone!').test('phoneNumber', PhoneNumberValidator.errorMessage, function (value) {
      if (typeof value === 'string') { 
        return new PhoneNumberValidator().validate(value);
      } else {
        return false;
      }
    }),
    companyEmail: yup
      .string()
      .email('The Email you entered is not a valid format!')
      .required('Please enter Email Address!'),
  });
};
