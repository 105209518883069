import {Dispatch} from 'redux';

import {axiosService} from '../../services';
import {fetchError, fetchStart, fetchSuccess, showMessage} from './Common';
import {AppActions} from '../../types';
import {SET_ACCOUNT_OPEN} from 'types/actions/User.actions';
import {RatingData} from 'modules/molecules';

export const setAccountOpen = (isAccountOpen: boolean): AppActions => ({
  type: SET_ACCOUNT_OPEN,
  isAccountOpen: isAccountOpen,
});

export const onActivateUser = (userId: any) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const res = await axiosService.activateUser(userId);
      dispatch(fetchSuccess());
      return Promise.resolve(res);
    } catch (err: any) {
      dispatch(fetchError(err));
    }
  };
};

export const onBlockUser = (userId: any) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const res = await axiosService.blockUser(userId);
      dispatch(fetchSuccess());
      return Promise.resolve(res);
    } catch (err: any) {
      dispatch(fetchError(err));
    }
  };
};

export const onRatingsGet = (userRole: string) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const res = await axiosService.getUserRatings(userRole);
      dispatch(fetchSuccess());
      return Promise.resolve(res);
    } catch (err: any) {
      dispatch(fetchError(err));
    }
  };
};

export const onRatingsSet = (tourId: string, ratings: RatingData[]) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const res = await axiosService.setUserRatings(tourId, ratings);
      dispatch(fetchSuccess());
      return Promise.resolve(res);
    } catch (err: any) {
      dispatch(fetchError(err));
    }
  };
};

export const onAccountGet = (id: string) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const res = await axiosService.accountGet(id);
      dispatch(fetchSuccess());
      return Promise.resolve(res);
    } catch (err: any) {
      dispatch(fetchError(err));
    }
  };
};

export const onAccountUserChange = (data: any) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const res = await axiosService.accountUserChange(data);
      dispatch(fetchSuccess());
      return Promise.resolve(res);
    } catch (err: any) {
      dispatch(fetchError(err));
    }
  };
};

export const onAccountOrganisationChange = (data: any) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const res = await axiosService.accountOrganisationChange(data);
      dispatch(fetchSuccess());
      return Promise.resolve(res);
    } catch (err: any) {
      dispatch(fetchError(err));
    }
  };
};

export const onAccountCarrierChange = (data: any) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const res = await axiosService.accountCarrierChange(data);
      dispatch(fetchSuccess());
      return Promise.resolve(res);
    } catch (err: any) {
      dispatch(fetchError(err));
    }
  };
};

export const onAccountShipperChange = (data: any) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const res = await axiosService.accountShipperChange(data);
      dispatch(fetchSuccess());
      return Promise.resolve(res);
    } catch (err: any) {
      dispatch(fetchError(err));
    }
  };
};

export const onOgranizationsGet = () => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const res = await axiosService.organizationsGet();
      dispatch(fetchSuccess());
      return Promise.resolve(res);
    } catch (err: any) {
      dispatch(fetchError(err));
    }
  };
};

export const onCreatableRolesGet = () => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const res = await axiosService.creatableRolesGet();
      dispatch(fetchSuccess());
      return Promise.resolve(res);
    } catch (err: any) {
      dispatch(fetchError(err));
    }
  };
};

export const onFilterableRolesGet = () => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const res = await axiosService.filterableRolesGet();
      dispatch(fetchSuccess());
      return Promise.resolve(res);
    } catch (err: any) {
      dispatch(fetchError(err));
    }
  };
};

export const onCreateUser = (
  data: any,
  roleId: number,
  organizationId: number,
) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const res = await axiosService.createUser(data, roleId, organizationId);
      dispatch(fetchSuccess());
      dispatch(showMessage('User successfully created'));
      return Promise.resolve(res);
    } catch (err: any) {
      dispatch(fetchError(err));
    }
  };
};

export const onCreateOrganization = (data: any) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const res = await axiosService.organizationCreate(data);
      dispatch(fetchSuccess());
      dispatch(showMessage('Organization successfully created'));
      console.log(res);
    } catch (err: any) {
      dispatch(fetchError(err));
    }
  };
};

export const onUpdateUser = (data: any, userId: string) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const res = await axiosService.updateUser(data, userId);
      dispatch(fetchSuccess());
      return Promise.resolve(res);
    } catch (err: any) {
      dispatch(fetchError(err));
    }
  };
};
