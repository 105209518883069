import React, {ChangeEvent} from 'react';

import Box from '@material-ui/core/Box';
import {Checkbox} from '@material-ui/core';

import {styles} from './form-signup-shipper.styles';
import {FormSignupShipperProps} from './form-signup-shipper.types';
import {amountTypes} from 'shared/constants/AppConst';

import {FormSelectPicker} from '../../atoms';

export const FormSignupShipper: React.FC<FormSignupShipperProps> = (props) => {
  const classes = styles();

  const onCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    props.setFieldValue(e.target.name, !props.acceptTerms);
    props.setAcceptTerms(!props.acceptTerms);
  };

  return (
    <>
      {/* Insurance amount */}
      <Box mb={{xs: 5, xl: 8}}>
        <FormSelectPicker
          name='insuredAmount'
          items={amountTypes}
          label='Insurance amount'
        />
      </Box>

      {/* Accept Terms and Conditions */}
      <Box mb={{xs: 3, xl: 4}} display='flex' alignItems='center' fontSize={15}>
        <Box display='flex' alignItems='center'>
          <Checkbox
            id='acceptTerms'
            name='acceptTerms'
            checked={props.acceptTerms}
            value={props.acceptTerms}
            color='primary'
            className={
              props.formValidated && !props.acceptTerms
                ? classes.textRed
                : classes.textGrey
            }
            onChange={(e) => onCheckboxChange(e)}
          />
          <Box
            className={
              props.formValidated && !props.acceptTerms
                ? classes.textRed
                : classes.textGrey
            }
            component='span'
            mr={2}
            fontSize={15}>
            I agree to
          </Box>
        </Box>
        <Box
          color='primary.main'
          component='span'
          fontSize={15}
          className={
            props.formValidated && !props.acceptTerms
              ? classes.textRed
              : classes.pointer
          }>
          {'Terms & Conditions'}
        </Box>
      </Box>
    </>
  );
};
