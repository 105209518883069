import React, {useState, useRef} from 'react';
import {Field, Form, Formik} from 'formik';
import {useDispatch} from 'react-redux';

import Box from '@material-ui/core/Box';
import {Button} from '@material-ui/core';

import {styles} from './form-account-shipper.styles';
import {FormAccountShipperProps} from './form-account-shipper.types';
import {onAccountShipperChange, setAccountOpen} from 'redux/actions';

import {FormFileField, FormTextField} from '../../atoms';

export const FormAccountShipper: React.FC<FormAccountShipperProps> = (
  props,
) => {
  const {shipper} = props;
  const classes = styles();
  const dispatch = useDispatch<any>();
  const formRef = useRef<any>(null);

  const [signatureFileName, setSignatureFileName] = useState<string>(
    'Signature Image',
  );
  const [signaturePath, setSignaturePath] = useState<string>(shipper.files.signature+"?"+Date().toString());

  const initialValues = {
    insuredAmount: shipper.insuranceAmount,
    signatureFile: null
  };
  
  const setCurrentValue = (e: string) => {
    setSignatureFileName(e)
    setSignaturePath(URL.createObjectURL(formRef.current.values['signatureFile']))
  }
  const handleClose = () => {
    dispatch(setAccountOpen(false));
  };

  return (
    <Box flex={1} display='flex' flexDirection='column' justifyContent='center'>
      {/* Form */}
      <Formik
        innerRef={formRef}
        validateOnChange={true}
        initialValues={initialValues}
        onSubmit={(data, {setErrors, setSubmitting}) => {
          setSubmitting(true);
          console.log('form data', data);
          dispatch(onAccountShipperChange(data)).then((resData: any) => {
            console.log('onAccountShipperChange - then - data - ', resData);
            handleClose();
          });
          setSubmitting(false);
        }}>
        {({isSubmitting, setFieldValue, values}) => (
          <Form noValidate autoComplete='off'>
            {/* Company Name */}
            <Box mb={{xs: 5, xl: 8}}>
              <FormTextField
                label='Insurance amount'
                name='insuredAmount'
                type='number'
                className={classes.myTextFieldRoot}
              />
            </Box>

            {/* Signature Image Upload */}
            <Box mb={{xs: 5, xl: 8}} style={{width: 'calc(100% - 4px)'}}>
              <Field
                name='signatureFile'
                component={FormFileField}
                label='Signature Image'
                accept='image/jpeg,image/png'
                currentValue={signatureFileName}
                setCurrentValue={setCurrentValue}
              />
            </Box>

            <Box mb={{xs: 5, xl: 8}}>
              <img
                style={{maxHeight: '300px'}}
                src={signaturePath}
                alt='Signature'
              />
            </Box>

            <Box mb={4} mt={10}>
              <Button
                variant='contained'
                color='default'
                className={classes.btnRoot}
                style={{marginRight: '20px'}}
                onClick={handleClose}>
                Cancel
              </Button>
              <Button
                variant='contained'
                color='primary'
                disabled={isSubmitting}
                className={classes.btnRoot}
                type='submit'>
                Save Changes
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
