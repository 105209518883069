import React from 'react';
import {useFormikContext, useField} from 'formik';

import {
  Checkbox,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from '@material-ui/core';

import {styles} from './form-multiple-select-picker.styles';
import {FormMultipleSelectPickerProps} from './form-multiple-select-picker.types';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const FormMultipleSelectPicker: React.FC<FormMultipleSelectPickerProps> = (
  props,
) => {
  const classes = styles();
  const {setFieldValue} = useFormikContext();
  const [field, meta] = useField(props.name);
  const errorText = meta.error && meta.touched ? meta.error : '';

  const renderValue = (selected: any) => {
    let value = '';
    console.log(selected);

    for (const sel of selected) {
      value += value !== '' ? ', ' : '';
      value += props.items[sel - 1].name;
    }
    return value;
  };

  return (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor={'select-multiple-checkbox-' + props.name}>
        {props.label}
      </InputLabel>
      <Select
        error={!!errorText}
        multiple
        value={field.value}
        onChange={(e) => {
          console.error(errorText);

          setFieldValue(props.name, e.target.value);
        }}
        input={<Input id={'select-multiple-checkbox-' + props.name} />}
        renderValue={renderValue}
        MenuProps={MenuProps}>
        {props.items.map((item: {id: number; name: string}) => (
          <MenuItem key={item.id} value={item.id}>
            <Checkbox
              color='primary'
              checked={field.value.indexOf(item.id) > -1}
            />
            <ListItemText primary={item.name} />
          </MenuItem>
        ))}
      </Select>
      <FormHelperText error={errorText ? true : false}>
        {errorText}
      </FormHelperText>
    </FormControl>
  );
};
