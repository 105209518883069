import React, {useState} from 'react';
import {Field, Form, Formik} from 'formik';
import {useDispatch} from 'react-redux';

import Box from '@material-ui/core/Box';
import {Button} from '@material-ui/core';

import {styles} from './form-account-carrier.styles';
import {FormAccountCarrierProps} from './form-account-carrier.types';
import {FormAccountCarrierValidationSchema} from './form-account-carrier.validation';
import {
  truckTypes,
  driverTypes,
  trailerTypes,
} from '../../../shared/constants/AppConst';
import {onAccountCarrierChange, setAccountOpen} from 'redux/actions';

import {FormFileField, FormTextField} from '../../atoms';
import {
  AccordionContainer,
  FormListSelector,
  FormListSelectorType,
} from '../../molecules';

export const FormAccountCarrier: React.FC<FormAccountCarrierProps> = (
  props,
) => {
  const {carrier} = props;
  const classes = styles();
  const dispatch = useDispatch<any>();
  const [firstFileName, setFirstFileName] = useState<string>(
    'Upload MC Authority file',
  );
  const [secondFileName, setSecondFileName] = useState<string>(
    'Upload Certifications of Insurance file',
  );
  const [thirdFileName, setThirdFileName] = useState<string>(
    'Upload W9 Form file',
  );
  const [fourthFileName, setFourthFileName] = useState<any>(
    'Factoring Company',
  );
  const [trucks, setTrucks] = useState<FormListSelectorType[]>(
    carrier.trucks.map((truck, index) => {
      return {id: index, typeId: truck.id, num: truck.count};
    }),
  );
  const [drivers, setDrivers] = useState<FormListSelectorType[]>(
    carrier.driverTeams.map((driver, index) => {
      return {id: index, typeId: driver.id, num: driver.count};
    }),
  );
  const [trailers, setTrailers] = useState<FormListSelectorType[]>(
    carrier.trailers.map((trailer, index) => {
      return {id: index, typeId: trailer.id, num: trailer.count};
    }),
  );

  const handleClose = () => {
    dispatch(setAccountOpen(false));
  };

  const onSetTrucks = (
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined,
    ) => void,
    newTrucks: FormListSelectorType[],
  ) => {
    setTrucks(newTrucks);
    setFieldValue('trucks', newTrucks);
  };
  const onSetDrivers = (
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined,
    ) => void,
    newDrivers: FormListSelectorType[],
  ) => {
    setDrivers(newDrivers);
    setFieldValue('driverTeams', newDrivers);
  };
  const onSetTrailers = (
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined,
    ) => void,
    newTrailers: FormListSelectorType[],
  ) => {
    setTrailers(newTrailers);
    setFieldValue('trailers', newTrailers);
  };

  return (
    <Box flex={1} display='flex' flexDirection='column' justifyContent='center'>
      {/* Form */}
      <Formik
        validateOnChange={true}
        initialValues={{
          mcNumber: carrier.mcNumber || '0',
          dotNumber: carrier.DOTNumber || '0',
          eldProvider: '',
          trucks: trucks,
          driverTeams: drivers,
          trailers: trailers,
        }}
        validationSchema={FormAccountCarrierValidationSchema}
        onSubmit={(data, {setErrors, setSubmitting}) => {
          console.log('submitting');

          setSubmitting(true);
          console.log('form data', data);
          dispatch(onAccountCarrierChange(data)).then((resData: any) => {
            console.log('onAccountCarrierChange - then - data - ', resData);
            handleClose();
          });
          setSubmitting(false);
        }}>
        {({isSubmitting, setFieldValue}) => (
          <Form noValidate autoComplete='off'>
            {/* Company details */}
            <AccordionContainer headerText='Company details'>
              <Box className={classes.accordionContainer} ml='6px' mr='6px'>
                <Box mb={{xs: 5, xl: 8}}>
                  <FormTextField
                    disabled={carrier.mcNumber !== '0'}
                    label='MC Number'
                    name='mcNumber'
                    type='number'
                    className={classes.myTextFieldRoot}
                  />
                </Box>
                <Box mb={{xs: 5, xl: 8}}>
                  <FormTextField
                    disabled={carrier.DOTNumber !== '0'}
                    label='DOT Number'
                    name='dotNumber'
                    type='number'
                    className={classes.myTextFieldRoot}
                  />
                </Box>
                <Box mb={{xs: 5, xl: 8}}>
                  <FormTextField
                    label='ELD Provider'
                    name='eldProvider'
                    type='text'
                    className={classes.myTextFieldRoot}
                  />
                </Box>
              </Box>
            </AccordionContainer>

            {/* File upload */}
            <AccordionContainer headerText='File Uploads'>
              <Box
                className={classes.accordionContainer}
                ml='6px'
                mr='6px'
                mb={4}>
                <Field
                  name='firstFile'
                  component={FormFileField}
                  label='MC Authority'
                  currentValue={firstFileName}
                  setCurrentValue={setFirstFileName}
                />
                <Field
                  name='secondFile'
                  component={FormFileField}
                  label='Certifications of Insurance'
                  currentValue={secondFileName}
                  setCurrentValue={setSecondFileName}
                />
                <Field
                  name='thirdFile'
                  component={FormFileField}
                  label='W9 Form'
                  currentValue={thirdFileName}
                  setCurrentValue={setThirdFileName}
                />
                <Field
                  name='fourthFile'
                  component={FormFileField}
                  label='Notice of Assignment'
                  currentValue={fourthFileName}
                  setCurrentValue={setFourthFileName}
                />
              </Box>
            </AccordionContainer>

            {/* Trucks */}
            <AccordionContainer headerText='Trucks'>
              <Box className={classes.accordionContainer}>
                <FormListSelector
                  itemTypes={truckTypes}
                  items={trucks}
                  setItems={(values) => onSetTrucks(setFieldValue, values)}
                  selectLabel='Truck type'
                />
              </Box>
            </AccordionContainer>

            {/* Driver Teams */}
            <AccordionContainer headerText='Driver Teams'>
              <Box className={classes.accordionContainer}>
                <FormListSelector
                  itemTypes={driverTypes}
                  items={drivers}
                  setItems={(values) => onSetDrivers(setFieldValue, values)}
                  selectLabel='Driver team type'
                />
              </Box>
            </AccordionContainer>

            {/* Trailers */}
            <AccordionContainer headerText='Trailers'>
              <Box className={classes.accordionContainer}>
                <FormListSelector
                  itemTypes={trailerTypes}
                  items={trailers}
                  setItems={(values) => onSetTrailers(setFieldValue, values)}
                  selectLabel='Trailer type'
                />
              </Box>
            </AccordionContainer>

            <Box mb={4} mt={4}>
              <Button
                variant='contained'
                color='default'
                className={classes.btnRoot}
                style={{marginRight: '20px'}}
                onClick={handleClose}>
                Cancel
              </Button>
              <Button
                variant='contained'
                color='primary'
                disabled={isSubmitting}
                className={classes.btnRoot}
                type='submit'>
                Save Changes
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
