import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
} from '@material-ui/core';
import {useTheme} from '@material-ui/core/styles';

import {ModalDialogProps} from './modal-dialog.types';

export const ModalDialog: React.FC<ModalDialogProps> = (props) => {
  const {title, message, buttons, buttonFunctions, open} = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        aria-labelledby='responsive-dialog-title'>
        <DialogTitle id='responsive-dialog-title'>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {buttons.map((button, index) => {
            return (
              <Button key={index} onClick={buttonFunctions[index]} color='primary'>
                {button}
              </Button>
            );
          })}
        </DialogActions>
      </Dialog>
    </div>
  );
};
