import React from 'react';

import {Box} from '@material-ui/core';

import {styles} from './form-subtitle.styles';
import {FormSubtitleProps} from './form-subtitle.types';
import {Fonts} from 'shared/constants/AppEnums';

export const FormSubtitle: React.FC<FormSubtitleProps> = (props) => {
  const classes = styles();
  return (
    <Box className={classes.header}>
      <Box
        component='h1'
        color='text.primary'
        fontWeight={Fonts.BOLD}
        fontSize={16}
        mb={1}>
        {props.label}
      </Box>
    </Box>
  );
};
