import React from 'react';

import {FormControl, InputLabel, MenuItem, Select} from '@material-ui/core';

import {styles} from './table-select-picker.styles';
import {TableSelectPickerProps} from './table-select-picker.types';

export const TableSelectPicker: React.FC<TableSelectPickerProps> = (props) => {
  const classes = styles();

  const onChangeHandler = (
    e: React.ChangeEvent<{name?: string | undefined; value: unknown}>,
  ) => {
    console.log(props.name, e.target.value);
    props.setSelected(props.name, e.target.value as string)
  };

  const items = [
    {
      id: 0,
      name: 'All',
    },
    ...props.items,
  ];

  return (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor='type-simple'>{props.label}</InputLabel>
      <Select
        value={props.selected}
        onChange={onChangeHandler}
        name={props.name}>
        {items.map((itemType: any) => {
          return (
            <MenuItem key={props.name + itemType.id} value={itemType.id}>
              {itemType.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
