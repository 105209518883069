import React from 'react';

import Rating from '@material-ui/lab/Rating';
import {Box, Typography} from '@material-ui/core';

// import {RatingWithLabelProps} from './rating-with-label.types';

export const RatingWithLabel: React.FC<any> = (props) => {
  const {data, onRatingChange, disabled} = props;

  const onChange = (event: React.ChangeEvent<{}>, value: number | null) => {
    if (value !== null) {
      onRatingChange(data.id, value);
    }
  };

  return (
    <Box key={data.id} component='fieldset' mb={3} borderColor='transparent'>
      <Typography component='legend'>{data.name}</Typography>
      <Rating
        disabled={disabled}
        name={data.name}
        value={data.value}
        precision={0.5}
        onChange={onChange}
      />
    </Box>
  );
};
