import React, {useEffect, useState} from 'react';
import clsx from 'clsx';

import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Snackbar from '@material-ui/core/Snackbar';

import {styles} from './snackbar-toast.styles';
import {AppSnackbarProps, variantIcon} from './snackbar-toast.types';

export const SnackbarToast: React.FC<AppSnackbarProps> = ({
  className,
  message,
  variant,
  onCloseHandler,
  ...other
}) => {
  const classes = styles();

  const [open, setOpen] = useState<boolean>(false);
  const Icon = variantIcon[variant];

  const onClose = (event: any, reason: any) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    onCloseHandler();
  };
  const onCloseX = (event: any) => {
    setOpen(false);
    onCloseHandler();
  };

  useEffect(() => {
    setOpen(true);
  }, [message]);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      autoHideDuration={6000}
      onClose={onClose}>
      <SnackbarContent
        className={clsx(classes[variant], className)}
        aria-describedby='client-snackbar'
        message={
          <span id='client-snackbar' className={classes.message}>
            <Icon className={clsx(classes.icon, classes.iconVariant)} />
            {message}
          </span>
        }
        action={[
          <IconButton
            key='close'
            aria-label='close'
            color='inherit'
            onClick={onCloseX}>
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
        {...other}
      />
    </Snackbar>
  );
};
