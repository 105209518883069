import React from 'react';

import {Permissions} from 'shared/constants/Permissions';

export const usersConfig = [
  {
    auth: [Permissions.USERS_SHIPPERS],
    routes: [
      {
        path: '/users/shippers/active',
        component: React.lazy(() => import('./user-list/user-list.page')),
      },
    ],
  },
  {
    auth: [Permissions.USERS_SHIPPERS],
    routes: [
      {
        path: '/users/shippers/blocked',
        component: React.lazy(() => import('./user-list/user-list.page')),
      },
    ],
  },
  {
    auth: [Permissions.USERS_SHIPPERS],
    routes: [
      {
        path: '/users/shippers/pending',
        component: React.lazy(() => import('./user-list/user-list.page')),
      },
    ],
  },
  {
    auth: [Permissions.USERS_CARRIERS],
    routes: [
      {
        path: '/users/carriers/active',
        component: React.lazy(() => import('./user-list/user-list.page')),
      },
    ],
  },
  {
    auth: [Permissions.USERS_CARRIERS],
    routes: [
      {
        path: '/users/carriers/blocked',
        component: React.lazy(() => import('./user-list/user-list.page')),
      },
    ],
  },
  {
    auth: [Permissions.USERS_CARRIERS],
    routes: [
      {
        path: '/users/carriers/pending',
        component: React.lazy(() => import('./user-list/user-list.page')),
      },
    ],
  },
  {
    auth: [Permissions.DISPLAY_USERS_SIDEMENU],
    routes: [
      {
        path: '/users/all',
        component: React.lazy(() => import('./user-list/user-list.page')),
      },
    ],
  },
  {
    auth: [Permissions.SUPER_ADMIN],
    routes: [
      {
        path: '/add-organization',
        component: React.lazy(
          () => import('../organization/organisation-create'),
        ),
      },
    ],
  },
  {
    auth: [Permissions.USER_CREATE],
    routes: [
      {
        path: '/user/create',
        component: React.lazy(() => import('./user-create/user-create.page')),
      },
    ],
  },

  {
    auth: [Permissions.USER_CREATE],
    routes: [
      {
        path: '/user/edit',
        component: React.lazy(() => import('./user-edit/user-edit.page')),
      },
    ],
  },
];
