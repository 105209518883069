import React from 'react';
import {useField} from 'formik';

import {TextField} from '@material-ui/core';

import {styles} from './form-text.field.styles';
import {FormTextFieldProps} from './form-text-field.types';

export const FormTextField: React.FC<FormTextFieldProps> = (props) => {
  const classes = styles();
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : '';
  return (
    <TextField
      disabled={props.disabled}
      {...field}
      value={field.value ?? ''}
      type={props.type}
      label={props.label}
      inputProps={{readOnly: props.readOnly ?? false}}
      className={[classes.textField, props.className].join(' ')}
      helperText={errorText}
      error={!!errorText}
      variant='outlined'
    />
  );
};
