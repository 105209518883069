import {parsePhoneNumber} from "libphonenumber-js";
export class PhoneNumberValidator {
    static readonly errorMessage = 'Please enter number in format (555) 555 5555';
    public validate(value:string):boolean
    {
        try {
            const phoneNumber = parsePhoneNumber(value, 'US');
            return phoneNumber.isValid();
        } catch (e) {
            //ToDo: skloniti ovo
            console.error(e);
            return false;
        }
    }
}