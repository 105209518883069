import React, {useState} from 'react';

import {FormControl, MenuItem, Select} from '@material-ui/core';

import {styles} from './table-row-select-picker.styles';
import {TableRowSelectPickerProps} from './table-row-select-picker.types';

export const TableRowSelectPicker: React.FC<TableRowSelectPickerProps> = (
  props,
) => {
  const classes = styles();
  const {row, rowId, data, selectedId, selectedName, onChange} = props;

  let items: {
    id: number;
    remoteId: number;
    text: string;
  }[] = [];
  let id = 1;
  data.forEach((x: {[x: string]: any}) => {
    items = [
      ...items,
      {
        id: id,
        remoteId: x['id'],
        text: x['text'],
      },
    ];
    id += 1;
  });
  const valueObj = items.filter((x) => {
    return x.remoteId === +row[selectedId];
  })[0];
  const initialValue = valueObj !== undefined ? valueObj.id : '';
  const [value, setValue] = useState<any>(initialValue);

  const serverRowId = row[rowId];
  console.log(
    'TableRowSelectPicker',
    row,
    rowId,
    data,
    selectedId,
    selectedName,
    serverRowId,
  );

  const onChangeHandler = (
    e: React.ChangeEvent<{name?: string | undefined; value: unknown}>,
  ) => {
    console.log(value, serverRowId, e.target.value);
    const newValue = e.target.value
    const valueObj = items.filter((x) => {
      return x.id === newValue;
    })[0];
    const changed = onChange(serverRowId, valueObj.remoteId)
    if (changed) {
      setValue(e.target.value);
    }
  };

  return (
    <FormControl className={classes.formControl}>
      <Select value={value} onChange={onChangeHandler} name={serverRowId}>
        {items.map((itemType: any) => {
          return (
            <MenuItem key={serverRowId + '-' + itemType.id} value={itemType.id}>
              {itemType.text}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
