import {Permissions} from 'shared/constants/Permissions';

export interface NavItemProps {
  id: string;
  messageId: string;
  title: string;
  icon?: string;
  exact?: boolean;
  url?: string;
  type?: string;
  count?: number;
  color?: string;
  auth?: number[];
  children?: NavItemProps[] | NavItemProps;
}

const routesConfig: NavItemProps[] = [
  {
    id: 'tours-shipper',
    title: 'All',
    messageId: 'sidebar.tours.shipper',
    type: 'item',
    url: '/tours',
    icon: 'dashboard',
    auth: [Permissions.LOADS_SHIPPER],
  },
  {
    id: 'tours-carrier',
    title: 'All',
    messageId: 'sidebar.tours.carrier',
    type: 'item',
    url: '/tours',
    icon: 'dashboard',
    auth: [Permissions.LOADS_CARRIER],
  },
  {
    id: 'loads-shipper',
    title: 'Loads',
    messageId: 'sidebar.loads.shipper',
    type: 'item',
    url: '/loads/shipper',
    icon: 'local_shipping',
    auth: [Permissions.LOADS_SHIPPER],
  },
  {
    id: 'loads-carrier',
    title: 'Loads',
    messageId: 'sidebar.loads.carrier',
    type: 'item',
    url: '/loads/carrier',
    icon: 'local_shipping',
    auth: [Permissions.LOADS_CARRIER],
  },
  {
    id: 'proposals-carrier',
    title: 'All',
    messageId: 'sidebar.proposals',
    type: 'item',
    url: '/offers/carrier',
    icon: 'work_outline',
    auth: [Permissions.PROPOSAL_LIST_CARRIER],
  },
  {
    id: 'add-organization',
    title: 'Add Organization',
    messageId: 'Add Organization',
    type: 'item',
    url: '/add-organization',
    icon: 'business',
    auth: [Permissions.SUPER_ADMIN],
  },
  {
    id: 'users',
    title: 'Users',
    messageId: 'sidebar.users',
    type: 'collapse',
    url: '/user-list',
    icon: 'people',
    auth: [
      Permissions.USERS_CARRIERS,
      Permissions.USERS_SHIPPERS,
      Permissions.USER_CREATE,
    ],
    children: [
      {
        id: 'users-shippers',
        title: 'Shippers',
        messageId: 'sidebar.users.shippers',
        type: 'collapse',
        auth: [Permissions.USERS_SHIPPERS],
        children: [
          {
            id: 'users-shippers-active',
            title: 'Active',
            messageId: 'sidebar.users.shippers.active',
            type: 'item',
            url: '/users/shippers/active',
          },
          {
            id: 'users-shippers-blocked',
            title: 'Blocked',
            messageId: 'sidebar.users.shippers.blocked',
            type: 'item',
            url: '/users/shippers/blocked',
          },
          {
            id: 'users-shippers-pending',
            title: 'Pending',
            messageId: 'sidebar.users.shippers.pending',
            type: 'item',
            url: '/users/shippers/pending',
          },
        ],
      },
      {
        id: 'users-carriers',
        title: 'Carriers',
        messageId: 'sidebar.users.carriers',
        type: 'collapse',
        auth: [Permissions.USERS_CARRIERS],
        children: [
          {
            id: 'users-carriers-active',
            title: 'Active',
            messageId: 'sidebar.users.carriers.active',
            type: 'item',
            url: '/users/carriers/active',
          },
          {
            id: 'users-carriers-blocked',
            title: 'Blocked',
            messageId: 'sidebar.users.carriers.blocked',
            type: 'item',
            url: '/users/carriers/blocked',
          },
          {
            id: 'users-carriers-pending',
            title: 'Pending',
            messageId: 'sidebar.users.carriers.pending',
            type: 'item',
            url: '/users/carriers/pending',
          },
        ],
      },

      {
        id: 'users-all',
        title: 'Users all',
        messageId: 'sidebar.users.all',
        type: 'item',
        url: '/users/all',
        auth: [Permissions.DISPLAY_USERS_SIDEMENU],
      },
      {
        id: 'users-create',
        title: 'Add New',
        messageId: 'sidebar.users.create',
        type: 'item',
        url: '/user/create',
        auth: [Permissions.USER_CREATE],
      },
    ],
  },
  {
    id: 'load-create',
    title: 'Add New',
    messageId: 'sidebar.loads.create',
    type: 'item',
    url: '/load',
    icon: 'add',
    auth: [Permissions.LOAD_CREATE],
  },
];
export default routesConfig;
