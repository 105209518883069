import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles((theme) => ({
  separator: {
    width: '100%',
    display: 'flex',
    paddingBottom: theme.spacing(5),
    borderBottom: '1px solid #E5E4EA',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  }
}));