import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  useMediaQuery,
} from '@material-ui/core';
import {useTheme} from '@material-ui/core/styles';

import {ModalInputDialogProps} from './modal-input-dialog.types';

export const ModalInputDialog: React.FC<ModalInputDialogProps> = (props) => {
  const {title, message, buttons, buttonFunctions, open, textPlaceholder, onTextChange} = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        aria-labelledby='responsive-dialog-title'>
        <DialogTitle id='responsive-dialog-title'>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
          <TextField
            autoFocus
            margin='dense'
            id={textPlaceholder}
            label={textPlaceholder}
            type='text'
            onChange={(e) => onTextChange(e.currentTarget.value as string)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          {buttons.map((button, index) => {
            return (
              <Button key={'inputDialog'+index} onClick={buttonFunctions[index]} color='primary'>
                {button}
              </Button>
            );
          })}
        </DialogActions>
      </Dialog>
    </div>
  );
};
