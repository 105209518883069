import React, {useEffect} from 'react';
import {useFormikContext, useField} from 'formik';

import {FormControl, InputLabel, MenuItem, Select} from '@material-ui/core';

import {styles} from './form-select-picker.styles';
import {FormSelectPickerProps} from './form-select-picker.types';

export const FormSelectPicker: React.FC<FormSelectPickerProps> = (
  props: any,
) => {
  const classes = styles();
  const {setFieldValue} = useFormikContext();
  const [field] = useField(props);

  useEffect(() => {
    if (!field.value) {
      setFieldValue(props.name, 1);
    }
  }, [field.value, setFieldValue, props.name]);

  return (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor='type-simple'>{props.label}</InputLabel>
      <Select
        value={field.value ?? 1}
        onChange={(e: any) => {
          if (props.onChange) {
            props.onChange(e.target.value);
          }
          setFieldValue(props.name, e.target.value);
        }}
        name={props.name}>
        {props.items.map((itemType: any) => {
          return (
            <MenuItem key={props.name + itemType.id} value={itemType.id}>
              {itemType.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
