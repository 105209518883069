import React, {ChangeEvent, useState, useEffect} from 'react';

import {Box, Button, Grid, TextField} from '@material-ui/core';
import {GridContainer} from '@crema';

import {styles} from './form-file-field.styles';
import {FormFileFieldProps} from './form-file-field.types';

export const FormFileField: React.FC<FormFileFieldProps> = (props) => {
  const {touched, errors} = props.form;
  const classes = styles();

  const [error, setError] = useState<string>('');

  const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files![0];
    if (file) {
      props.form.setFieldTouched(props.field.name);
      props.form.setFieldValue(props.field.name, file);
      await Promise.resolve()
      props.setCurrentValue(file.name);
    }
  };

  useEffect(() => {
    if (touched[props.field.name]) {
      setError(errors[props.field.name]?.toString() ?? '');
    }
  }, [errors, props.field.name, touched]);

  return (
    <GridContainer mb={{xs: 3, xl: 4}}>
      <Grid item xs={12} md={9}>
        <Box ml={1}>
          <TextField
            className={classes.textField}
            label={props.label}
            type='text'
            error={!!error}
            helperText={error}
            value={props.currentValue}
            disabled
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={3}>
        <Box>
          <input
            style={{display: 'none'}}
            id={props.field.name}
            type='file'
            name={props.field.name}
            accept={props.accept}
            onChange={(e) => onChange(e)}
          />
          <label htmlFor={props.field.name}>
            <Button variant='contained' color='primary' component='span'>
              Upload
            </Button>
          </label>
        </Box>
      </Grid>
    </GridContainer>
  );
};
