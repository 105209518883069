import React from 'react';

import { Permissions } from "shared/constants/Permissions";

export const toursConfig = [
  {
    auth: [Permissions.TOURS_ALL], 
    routes: [
      {
        path: '/tours',
        component: React.lazy(() => import('./tour-list/tour-list.page')),
      },
    ],
  },
];
