import { Dispatch } from 'redux';

import { axiosService } from '../../services';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import { AppActions } from '../../types';

export const onTourGetTrack = (tourId: any) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const res = await axiosService.tourGetTrack(tourId)
      dispatch(fetchSuccess());
      return Promise.resolve(res);
    } catch (err: any) {
      dispatch(fetchError(err));
    }
  };
};

export const onTourGetAssignees = () => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const res = await axiosService.tourGetAssignees()
      dispatch(fetchSuccess());
      return Promise.resolve(res);
    } catch (err: any) {
      dispatch(fetchError(err));
    }
  };
};

export const onTourAssign = (tourId: any, driverId: any) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const res = await axiosService.tourAssign(tourId, driverId)
      dispatch(fetchSuccess());
      return Promise.resolve(res);
    } catch (err: any) {
      dispatch(fetchError(err));
    }
  };
};

export const tourChangeStatus = (tourId: any, statusId: number) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const res = await axiosService.tourChangeStatus(tourId, statusId)
      dispatch(fetchSuccess());
      return Promise.resolve(res);
    } catch (err: any) {
      dispatch(fetchError(err));
    }
  };
};