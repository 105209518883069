import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles((theme) => ({
  root: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '90%',
    },
    '& .MuiStepper-horizontal': {
      [theme.breakpoints.down('xs')]: {
        alignItems: 'flex-start',
        flexDirection: 'column',
      },
    },
    '& .MuiStep-horizontal': {
      [theme.breakpoints.down('xs')]: {
        paddingTop: 8,
        paddingBottom: 8,
        width: '100%',
      },
      '& .MuiStepConnector-alternativeLabel': {
        [theme.breakpoints.down('xs')]: {
          display: 'none',
        },
      },
    },
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));