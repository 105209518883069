import * as yup from 'yup';
import { PhoneNumberValidator} from '../../../shared/validators';

export const FormAccountUserValidationSchema = (role: string) => {
    return yup.object({
        name: yup.string().required('Please enter name!'),
        email: yup
            .string()
            .email('The Email you entered is not a valid format!')
            .required('Please enter Email Address!'),
        username: yup.string().required('Please enter username!'),
        oldPassword: yup.string()
            //.required('Please enter old password!')
            .min(8, 'Password is too short - should be 8 chars minimum'),
        newPassword: yup.string()
            //.required('Please enter new password!')
            .min(8, 'Password is too short - should be 8 chars minimum'),
        phone: new yup.string().required('Please enter phone!').test('phoneNumber', PhoneNumberValidator.errorMessage, function (value) {
            if (typeof value === 'string') { 
              return new PhoneNumberValidator().validate(value);
            } else {
              return false;
            }
        })
    });
};