import * as yup from 'yup';
import {dateHelper} from '../../../shared/helpers';

export const FormLoadCreateGeneralValidationSchema = () => {
  return yup.object({
    pickupDatetimeStart: yup
      .date()
      .min(dateHelper.now(), "Start can't be before current date and time."),
    addPickupDatetimeRange: yup.boolean().default(false),
    pickupDatetimeEnd: yup.date().when('addPickupDatetimeRange', {
      is: (addPickupDatetimeRange) => {
        return !!addPickupDatetimeRange ? true : false;
      },
      then: yup
        .date()
        .min(
          yup.ref('pickupDatetimeStart'),
          "Deadline can't be before pickup start date",
        ),
    }),

    dropoffDatetimeStart: yup
      .date()
      .min(
        yup.ref('pickupDatetimeStart'),
        "Dropoff can't be before pickup start date",
      ),
    addDropoffDatetimeRange: yup.boolean().default(false),
    dropoffDatetimeEnd: yup.date().when('addDropoffDatetimeRange', {
      is: (addDropoffDatetimeRange) => {
        return !!addDropoffDatetimeRange ? true : false;
      },
      then: yup
        .date()
        .min(
          yup.ref('dropoffDatetimeStart'),
          "Deadline can't be before dropoff start date",
        ),
    }),

    offerExpirationDatetime: yup
      .date()
      .max(
        yup.ref('pickupDatetimeStart'),
        "Expiration can't be after pickup start date",
      )
      .min(
        dateHelper.now(),
        "Expiration can't be before current date and time.",
      ),
    loadEquipmentSelect: yup.string().required('Please select load equipment'),
  });
};
